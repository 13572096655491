import React from 'react';
import { Grid, Card, CardContent, Typography, Button, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { motion } from 'framer-motion';

const fadeInFromLeft = {
    initial: {
      x: '-100vw',   // start from the left outside the view
      opacity: 0,
    },
    animate: {
      x: 0,         // move to original position
      opacity: 1,
    },
    exit: {
      x: '-100vw',   // move back to the left outside the view
      opacity: 0,
    }
  };
  

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "#8fb3bd",
    borderRadius: "25px",
    border: '3px solid #544C40', 
    boxShadow: '8px 8px 16px rgba(52, 47, 40, 0.85), -8px -8px 16px rgba(252, 241, 216, 0.5)', 
    marginBottom: "100px" 

  },
  typographySection: {
    padding: theme.spacing(2),
    color: "white",
    position: "relative",
    left: "-3%",
    top: "-25px"
  },
  card: {
    padding: theme.spacing(1),
    textAlign: 'center',
    height: '100%', 
    marginBottom: theme.spacing(0.5),


    background: 'linear-gradient(135deg, #FFDAB9 0%, #D8BFD8 50%, #9370DB 100%)',
    background: 'linear-gradient(135deg, #9decc4 0%, #b3a1e6 100%)',

    // background: 'linear-gradient(135deg, #ADD8E6 0%, #D8BFD8 50%, #DDA0DD 100%)',


    color: '#544C40',  
    border: '3px solid #544C40',  
    borderRadius: '12px',  
    boxShadow: '8px 8px 16px rgba(52, 47, 40, 0.85), -8px -8px 16px rgba(252, 241, 216, 0.3)',  
    transition: 'all 0.2s ease-in-out',
  },
  subCard: {
    border: '3px solid #544C40',  
    borderRadius: '12px',  
    boxShadow: '8px 8px 16px rgba(52, 47, 40, 0.85), -8px -8px 16px rgba(252, 241, 216, 0.3)',  
    marginTop: theme.spacing(1.5),
    padding: theme.spacing(0.3),
    border: '1px solid #e0e0e0',
    transform: 'scale(1)',  // start with the normal scale
    transition: 'transform 0.3s ease-in-out',
  },
  startButton: {
    marginTop: "20px",
    backgroundColor: "white",

    border: '2px solid #91887a',  // Thicker border
    borderRadius: '12px',  // Bold corners
    // boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.8), -8px -8px 16px rgba(0, 0, 0, 0.3)',
    padding: theme.spacing(1.5, 4),
    fontSize: '1.2rem',
    padding: theme.spacing(1, 3),
    marginBottom: theme.spacing(3),
    '&:hover': {
      backgroundColor: '#FFED8A',
      color: '#2b2d30'
    },
  },
  creditsInfo: {
    fontSize: '1.2rem',
    color: "#f2d9a2",  // adjust color as needed
  },
  hovered: {
    transform: 'scale(1.1)',
  },

  notHovered: {
    transform: 'scale(0.9)',
  },
  price: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  features: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'left',
    fontSize: '1.0rem',
    '& li': {  // Targeting the list items inside the features class
      marginBottom: theme.spacing(2),  // Adjust this value to increase/decrease spacing
      fontSize: "1.2rem"
    },
    
  },
  subCardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: '2rem',
    marginBottom: theme.spacing(1),
  },
  mobileRoot: {
    padding: theme.spacing(2),
  },
  mobileTypographySection: {
    padding: theme.spacing(1),
    top: "0",
  },
  mobileCard: {
    margin: theme.spacing(2, 0),
  },
}));

export default function LandingPricingComponent({handleButtonClick}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs')); // checks if the device screen width is up to xs

  const subCardsBasic = [
    {
      title: "Credits Pack 1",
      description: "25 Credits",
      price: 5,
      icon: <MailOutlineIcon className={classes.icon} style={{color: "#cd7f32"}}/>,
      savings: 0, 
      color: "#cd7f32" 
    },
    {
      title: "Credits Pack 2",
      description: "50 Credits",
      price: 10,
      icon: <MailOutlineIcon className={classes.icon} style={{color: "#C0C0C0"}} />,
      savings: 1,
      color: "#C0C0C0"  // Silver
    },
    {
      title: "Credits Pack 3",
      description: "100 Credits",
      price: 15,
      icon: <MailOutlineIcon className={classes.icon} style={{color: "#FFD700"}} />,
      savings: 5,
      color: "#FFD700"  // Gold
    },
  ];

  const subCardsPremium = [
    {
      title: "Premium Pack",
      description: "1 Month",
      price: 25,
      icon: <TimelapseIcon className={classes.icon} style={{color: "#cd7f32"}}/>,
    },
  ];
  const [hoveredSubCard, setHoveredSubCard] = React.useState(null);


  return (
    <motion.div 
        initial={fadeInFromLeft.initial} 
        animate={fadeInFromLeft.animate} 
        exit={fadeInFromLeft.exit}
    >
      <Grid container className={isMobile ? classes.mobileRoot : classes.root} spacing={3}>
        <Grid item xs={12} sm={12} md={3} className={isMobile ? classes.mobileTypographySection : classes.typographySection}>
          <Typography
            style={{
              fontFamily: '"Bungee", sans-serif',
              fontSize: "2.5rem",
              marginBottom: "20px",
            }}
            variant="h4" gutterBottom>
              Pricing
          </Typography>
          <Typography
            style={{
              fontFamily: '"Roboto", sans-serif',
              fontSize: "1.2rem",
            }}
            variant="body1">
            <ul>
              <li
              style={{
                marginBottom: "10px"
              }}
              ><strong>New Users:</strong> New users receive 50 free credits to access our premium features immediately upon registration.</li>

              <li
              style={{
                marginBottom: "10px"
              }}
              ><strong>Free:</strong> Enjoy up to 30 cover letters and question answering each month at no cost. Purchase credits when you need them.</li>
              <li><strong>Premium:</strong> Get unlimited access to creating cover letters, answer job questions and CV enhancements and tips.</li>
            </ul>
          </Typography>

        </Grid>

        <Grid item xs={12} sm={12} md={4} className={isMobile ? classes.mobileCard : ''}>
          <Card 
          style={{
            background: "white"
          }}
          className={classes.card}>
          <CardContent>
          <Typography 
              style={{fontWeight: "bold", fontSize: "1.8rem"}}
              variant="h5" gutterBottom>
                  Casual Job Seeker 👀
          </Typography>
          <Typography 
              style={{fontWeight: null}}
              variant="h6" gutterBottom>
                  Free
          </Typography>
          <div className={classes.features}>
              <ul>
                  <li>🤖 GPT-4 powered AI outputs</li>
                  <li>✉️ 30 Cover letters per month</li>
                  <li>📌 30 Job-Ad question answers per month</li>
                  <li>📝 15 CV enhancements per month</li>
              </ul>
          </div>
          <Button 
            onClick={handleButtonClick}
            variant="contained" className={classes.startButton}>
              Try now
          </Button>
      </CardContent>

          </Card>
        </Grid>

        <Grid item  xs={12} sm={12} md={4} className={isMobile ? classes.mobileCard : ''}>
          <Card 
          style={{
            height: "100%"
          }}
          className={classes.card}>
          <CardContent>
              <Typography 
                  style={{fontWeight: "bold", color: "white", fontSize: "1.8rem"}}
                  variant="h5" gutterBottom>
                    Active Job Hunter 💼
              </Typography>
              <Typography 
              style={{fontWeight: null, color: "white"}}
              variant="h6" gutterBottom>
                  12$ / month
              </Typography>
              <div 
               style={{ color: "white"}}

              className={classes.features}>
                  <ul>
                      
                      <li>🦾 GPT-4 powered AI outputs</li>
                      <li>✉️ Unlimited cover letters</li>
                      <li>📌 Unlimited Job-Ad question answers</li>
                      <li>📝 Unlimited access to all CV enhancement feature</li>
                      <li>⚙️ Access to GPT-4 behavior customization</li>

                  </ul>
              </div>
              <Button 
                onClick={handleButtonClick}
                variant="contained" className={classes.startButton}>
                  Go Premium
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </motion.div>
);

}
