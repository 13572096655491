import React,{useEffect, useState} from 'react';
import { Typography, Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ImageLaptop from '../../images/laptop2.png';
import { AnimatePresence, motion } from 'framer-motion';
import { useTheme, useMediaQuery } from '@material-ui/core';  
import GifCoverLetter from '../../images/gif/cover_gif1.gif'
import GifQA from '../../images/gif/qa_gif1.gif'


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '55vh',

    marginTop: "45%",

    '@media (max-width: 1200px)': {
      marginTop: "45%",
    },
  
    '@media (max-width: 868px)': {
      marginTop: "55%",
    },
    '@media (max-width: 800px)': {
      marginTop: "85%",
    },
    '@media (max-width: 650px)': {
      marginTop: "95%",
    },
    '@media (max-width: 600px)': {
      marginTop: "165%",
    }
  },

  textContainer: {
    padding: theme.spacing(4),
  },
  imageContainer: {
    marginTop:"5%",
    padding: theme.spacing(4),
    position: 'relative',
    width: '100%',
    paddingTop: '48.25%', // For 16:9 aspect ratio
    backgroundImage: `url(${ImageLaptop})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    overflow: 'hidden', // Hide any overflowing content
    [theme.breakpoints.down('sm')]: {  // Mobile view adjustments
      paddingTop: '100%',
      top: "-100px",

    }
  },
  title: {
    fontFamily: '"Bungee", sans-serif',
    fontSize: "2.5rem",

    marginBottom: "2rem",
    [theme.breakpoints.down('md')]: {
      marginTop: "5rem",
      fontSize: "2.1rem",
    },
    [theme.breakpoints.down('sm')]: {  // Mobile view adjustments
      marginTop: "5rem",
      fontSize: "1.8rem",

    }
  },
  emojiBullet: {  // This targets the emoji specifically
    fontSize: '1.7rem',  // Adjust the size as desired
    marginRight: '10px',
  },
  bulletStyle: {
    listStyleType: 'none',
    paddingLeft: '0',
    margin: '0',
  
    '& li': {
      marginBottom: '0.5rem',
      display: 'flex',
      alignItems: 'center',
    },
  
    '& .emojiBullet': {  // This targets the emoji specifically
      fontSize: '2.5rem',  // Adjust the size as desired
      marginRight: '10px',
    },
  },
  
  

  bulletText: {
    fontSize: "1.4rem",
    marginBottom: '0.5rem',
    fontFamily: '"Roboto", sans-serif',
    [theme.breakpoints.up('md')]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down('sm')]: {  // Mobile view adjustments
      fontSize: "1.1rem",

    }
    
  },
  subText: {
    fontFamily: '"Roboto", sans-serif',
    marginBottom: theme.spacing(1),
    fontSize: "1.3rem"
  },
  video: {
    position: 'absolute',
    top: 'calc(29.2% - (15% * 0.5625))', // Adjust positioning based on aspect ratio for desktop
    left: '15%',
    width: '70%',
    zIndex: 1,
    height: 'calc(74% * 0.5625)', // Maintain 16:9 aspect ratio for desktop

    [theme.breakpoints.down('sm')]: {  // Mobile view adjustments
      top: '22%',
      left: '15%',
      width: '70%',
      height: 'calc(70% * 0.5625)',

    }
  }
}));


export default function LandingBenefitComponent() {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  

  const [currentGif, setCurrentGif] = useState('coverLetter');
  const textContainerVariants = {
    hidden: { opacity: 0, x: '-20vw' },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentGif(prev => prev === 'coverLetter' ? 'qa' : 'coverLetter');
    }, 10000); // 5 seconds
    
    return () => clearTimeout(timer);  // Cleanup to prevent multiple timers
  }, [currentGif]);
  
  const gifs = {
    coverLetter: {
      title: "Let GPT-4 create Cover Letters",
      src: GifCoverLetter
    },
    qa: {
      title: "Let GPT-4 answer job-ad questions for you",
      src: GifQA
    }
  };

  const gifVariants = {
    enter: { opacity: 1, x: 0, transition: { duration: 1 } },
    exit: { opacity: 0, x: '100vw', transition: { duration: 1 } },
    initial: { opacity: 0, x: '-10%' }
  };
  


  return (
    <Grid 

    style={{

      position:"relative",

    }}
    container className={classes.root}>
      {/* Typography Section */}
      <Grid item xs={12} md={6}

      className={classes.textContainer}>
      <motion.div 
          initial="hidden"
          animate="visible"
          variants={textContainerVariants}
          
        ><Typography variant="h4" gutterBottom className={classes.title}>
        Maximize your job application efficiency with AI
      </Typography>
      <ul className={classes.bulletStyle}>
        <li>
          <span className={classes.emojiBullet}>🚀</span> 
          <Typography component="span" className={classes.bulletText}>
            Craft standout cover letters tailored to each job.
          </Typography>
        </li>
        <li>
          <span className={classes.emojiBullet}>💡</span>
          <Typography component="span" className={classes.bulletText}>
            Auto-answer job ad questions effortlessly.
          </Typography>
        </li>
        <li>
          <span className={classes.emojiBullet}>🤖</span>
          <Typography component="span" className={classes.bulletText}>
            Receive AI-driven CV tips and enhancements.
          </Typography>
        </li>
        <li>
          <span className={classes.emojiBullet}>⏰</span>
          <Typography component="span" className={classes.bulletText}>
            Save time, enhance efficiency, and make a lasting impression on recruiters.
          </Typography>
        </li>
        <li style={{ marginLeft: '10%' }}>
            <Typography component="span" className={classes.subBulletText}>
                1. Upload CV to Link Letter Pro
            </Typography>
        </li>
        <li style={{ marginLeft: '10%' }}>
            <Typography component="span" className={classes.subBulletText}>
                2. Sync <a href="https://chromewebstore.google.com/detail/link-letter-pro/hggckppbcbgancgnmnpdclipioalfhgo?hl=sv&fbclid=IwAR0r7hoxR6TQhrLdLqxN4jgBQOQSeWvlUssI4dLoxhVCxJ-AvLXg_41Z1dw" style={{ }}>Chrome extension</a> with Linkedin Job-Ad
            </Typography>
        </li>
        <li style={{ marginLeft: '10%' }}>
            <Typography component="span" className={classes.subBulletText}>
                3. Generate Cover Letters / Answer Job-Ad Questions
            </Typography>
        </li>
      </ul>
    

        </motion.div>
      </Grid>
      
      {/* Laptop Image with Embedded YouTube Video */}
      <AnimatePresence mode='wait'>
      <Grid item md={6} xs={12}
      
      style={{
        position: "relative",
        overflowX: "hidden",
        overflowY: "auto"
      }}>
      <motion.div
        key={currentGif}
          initial="initial"
          animate="enter"
          exit="exit"
          variants={gifVariants}

      >
        <img 
          style={{
            width: isMobile ? "80%" : (isTablet ? "50%" : "58%"), 
            marginLeft: isMobile ? "10%" : (isTablet ? "25%" : "20%"),
            marginTop: isMobile ? null : (isTablet ? null : "8%"),
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(0, 0, 0, 0.2)',
            borderRadius: "10px",
            border: '1.4px solid #544C40',

          }}
          src={gifs[currentGif].src}
        />

        </motion.div>
      </Grid>
      </AnimatePresence>
      
    </Grid>
  );
}
