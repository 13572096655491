import React, { useState } from 'react';
import TypewriterComponent from './TypewriterComponent';
import LinkedInJobAdComponent from './LinkedInJobAdComponent';
import jobMessages from './Data';
import { makeStyles } from '@material-ui/core/styles';

import { 
    Button,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '69vh',
    display: 'flex',
    alignItems: 'center',  // Center vertically
    justifyContent: 'space-between',  // Space out the items
    zIndex: 1
  },

  ctaButton: {
    backgroundColor: '#e2cffc',
    zIndex:1,
    color: '#544C40',
    border: '2px solid #544C40',
    borderRadius: '12px',
    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.3), -4px -4px 8px rgba(0, 0, 0, 0.3)',
    padding: theme.spacing(1.5, 4),
    fontSize: '1.2rem',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: '#ccadf7',
      boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.2), -8px -8px 16px rgba(255, 255, 255, 0.1)',
    },
    '&:active': {
      boxShadow: 'inset 4px 4px 8px rgba(52, 47, 40, 0.25), inset -4px -4px 8px rgba(252, 241, 216, 0.9)',
    }
  }
}))

const AnimationManager = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const classes = useStyles();
    const startTypewriterAnimation = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % jobMessages.length);
    };

    return (
        <div className={classes.root}>
            <LinkedInJobAdComponent 
              jobMessages={jobMessages}
              currentIndex={currentIndex}
            />

            <Button className={classes.ctaButton} onClick={startTypewriterAnimation}>GENERATE</Button>

            <TypewriterComponent 
                data={jobMessages}
                currentIndex={currentIndex}
            />
        </div>
    );
}

export default AnimationManager;
