import React from 'react';
import { Card, CardContent, AppBar, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { motion, AnimatePresence } from 'framer-motion';



const variants = {
  enter: { x: '-100%', opacity: 0 },
  center: { x: '0%', opacity: 1 },
  exit: { x: '40%', opacity: 0 }
};

const useStyles = makeStyles((theme) => ({
  jobAdCard: {
    width: 'calc(69vh * 2 / 3)',
    backgroundColor: '#FFFFFF',
    color: '#000000',  
    border: '3px solid #544C40',
    borderRadius: '12px',
    boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.6), -8px -8px 16px rgba(255, 255, 255, 0.1)',
    transition: 'all 0.2s ease-in-out',
  },
  linkedInLogo: {
    marginRight: theme.spacing(1),
  },
  appBar: {
    height: "50px",
    borderRadius: '9px 9px 0 0',
    color: 'white',
    backgroundColor: '#2867B2',
    padding: theme.spacing(1),
  },
  jobAdContent: {
    color: '#000000',
  },
  title: {
    fontWeight: "bold",
    marginBottom: "10px",
    fontSize: "20px"
  }
}));

const LinkedInJobAdComponent = ({ jobMessages, currentIndex }) => {
  const classes = useStyles();
  const currentMessage = jobMessages[currentIndex];

  return (
    <AnimatePresence mode='wait'>
      <motion.div
        key={currentIndex}
        initial="enter"
        animate="center"
        exit="exit"
        variants={variants}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        <Card className={classes.jobAdCard}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.title}>
                    <LinkedInIcon 
                        style={{
                        fontWeight: "bold",
                        position: "relative",
                        top:"-12px"
                      }}
                    className={classes.linkedInLogo} /> {/* Add the LinkedIn logo */}
                    <Typography 
                    style={{
                      fontWeight: "bold",
                      position: "relative",
                      top:"-12px"
                    }}
                    variant="h6">
                        LinkedIn Ad
                    </Typography>
                </Toolbar>
            </AppBar>
            <CardContent>
                <Typography className={classes.title}>
                    {currentMessage.jobAdTitle}
                </Typography>
                <Typography className={classes.jobAdContent}>
                    {currentMessage.jobAdContent}
                </Typography>
            </CardContent>
        </Card>
      </motion.div>
    </AnimatePresence>
  );
}

export default LinkedInJobAdComponent;