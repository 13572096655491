import React, { useState } from 'react';
import { Button, Typography, Container, TextField, Box, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'; // Import the checkmark icon
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    subscriptionContainer: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    card: {
        backgroundColor: '#FFFFFF',
        border: '2px solid #544C40',
        borderRadius: '12px',
        boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.5), -8px -8px 16px rgba(255, 255, 255, 0.1)',
        padding: theme.spacing(3),
        maxHeight: "240px",
        width: "40%",
        position: "relative",
        left: "30%",
        [theme.breakpoints.down('sm')]: {
            maxHeight: "680px",
            width: "80%",
            left: "10%",
        }
    },
    subscriptionText: {
        fontFamily: '"Roboto", sans-serif',
        fontSize: '1.0rem',
        marginBottom: '0rem',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            maxHeight: "680px",
            width: "80%",
        }
    },
    emailField: {
        marginBottom: '1rem',
    },
    subscribeButton: {
        backgroundColor: '#FCF1D8',
        color: '#544C40',
        border: '1.4px solid #544C40',
        borderRadius: '12px',
        boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.5), -8px -8px 16px rgba(255, 255, 255, 0.1)',
        padding: theme.spacing(1.5, 4),
        fontSize: '0.9rem',
        transition: 'all 0.2s ease-in-out',
        
        '&:hover': {
            backgroundColor: '#EDE4D0',
            boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.8), -8px -8px 16px rgba(255, 255, 255, 0.1)',
        },
        '&:active': {
            boxShadow: 'inset 4px 4px 8px rgba(52, 47, 40, 0.25), inset -4px -4px 8px rgba(252, 241, 216, 0.9)',
        }
    },
    buttonBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    checkIcon: {
        verticalAlign: 'bottom',
        fontSize: "1.7rem",
        color: "#B2A9E3",
        marginLeft: theme.spacing(1),
    },
}));

// Helper function to validate email format
const validateEmail = (email) => {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

const SubscriptionForm = ({backendUrl}) => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async () => {
        if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        setIsLoading(true);
        setError('');

        try {
            // Replace '/api/subscribe' with the endpoint to your subscription API
            const response = await axios.post(backendUrl+'/subscribe', { email: email });
            
            console.log(response.data.success)
            // Check if the response has data and if the subscription was successful
            if (response.data) {
                setIsSubscribed(true);
                setEmail(''); // Clear the email input field
            } else {
                setError('Failed to subscribe. Please try again later.');
            }
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Container className={classes.subscriptionContainer}>
            <Card className={classes.card}>
                <CardContent>
                    {isSubscribed ? (
                        <Typography style={{
                            fontFamily: '"Roboto", sans-serif',
                            fontSize: '1.2rem',
                            marginBottom: '0rem',
                            textAlign: 'center',

                        }}>
                            Subscribed successfully!
                            <CheckCircleOutlineIcon className={classes.checkIcon} color="primary" />
                        </Typography>
                    ) : (
                        <>
                            <Typography className={classes.subscriptionText}>
                                Stay updated with our latest features and offers!
                            </Typography>
                            <TextField
                                className={classes.emailField}
                                fullWidth
                                variant="outlined"
                                label="Email"
                                error={!!error}
                                helperText={error}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                disabled={isLoading}
                            />
                            <Box className={classes.buttonBox}>
                                <Button
                                    className={classes.subscribeButton}
                                    onClick={handleSubmit}
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Subscribing...' : 'Subscribe'}
                                </Button>
                            </Box>
                        </>
                    )}
                </CardContent>
            </Card>
        </Container>
    );
};

export default SubscriptionForm;
