import React,{useRef, useEffect, useState} from 'react';
import { 
  Button, Typography, Container, Box, Grid, 
  Card, CardContent, CardHeader 
} from '@material-ui/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { motion, useInView, useAnimation, inView } from 'framer-motion';

import { makeStyles } from '@material-ui/core/styles';
import ImageHand from '../../images/hand_landingpage.png';

import AnimationManager from './AnimationManager';
import SubscriptionForm from './SubscriptionForm';
import DoodleSVG from '../../svg/Doodle1.svg'
import DoodleSVG2 from '../../svg/Doodle2.svg'
import DoodleSVG3 from '../../svg/Doodle3.svg'
import DoodleSVG4 from '../../svg/Doodle4.svg'
import DoodleSVG5 from '../../svg/Doodle5.svg'
import DoodleSVG6 from '../../svg/Doodle6.svg'
import DoodleSVG7 from '../../svg/Doodle7.svg'

import DoodleSVG9 from '../../svg/Doodle9.svg'

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


import YouTubeIcon from '@material-ui/icons/YouTube';
import LandingBenifitComponent from './LandingBenifitComponent';
import LandingPricingComponent from './LandingPricingComponent';
import { useTheme, useMediaQuery } from '@material-ui/core'; 
import FAQ from './FAQ';

import AISVG from '../../svg/ai.svg'
import ApprovalSVG from '../../svg/approval.svg'
import SpeedSVG from '../../svg/speed.svg'
gsap.registerPlugin(ScrollTrigger);



const staggeredEntrance = {
  initial: {},
  animate: {
    transition: {
      staggerChildren: 0.2
    }
  }
};





const handAnimationVariants = {
  initial: {
    x: 0,
    y: 0
  },
  float: {
    y: ["0%", "10%", "0%"],
    transition: {
      duration: 4,
      repeat: Infinity,
      ease: "easeInOut"
    }
  },
  exitFrame: {
    x: "800px",
    y: 0,
    transition: {
      duration: 0.5
    }
  },
  enterFrame: {
    x: 0,
    y: ["100%", "0%"],
    transition: {
      duration: 1.5
    }
  }
};



const scaleAndFadeIn = {
  hidden: { opacity: 0, scale: 0.8, x: -20 },  // start slightly scaled down, hidden, and skewed left
  visible: { opacity: 1, scale: 1, x: 0 }     // move to its natural position, original size and become visible
};

const slideUpFadeIn = {
  hidden: { opacity: 0, y: 50 },  // start from 50 pixels down and hidden
  visible: { opacity: 1, y: 0 }   // move to its natural position and become visible
};

const tiltAndFadeIn = {
  hidden: { opacity: 0, skew: 10 },       // start tilted 10 degrees and hidden
  visible: { opacity: 1, skew: 0 }        // move to its natural position and become visible
};
const bounceIn = {
  hidden: { opacity: 0, scale: 1.3 },   // start slightly scaled up and hidden
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: {
      type: 'spring',
      stiffness: 500,
      damping: 30
    }
  }                                     // spring animation back to its natural size and become visible
};

const rotateAndFadeIn = {
  hidden: { opacity: 0, rotate: -15 },  // start rotated counter-clockwise by 15 degrees and hidden
  visible: { opacity: 1, rotate: 0 }    // rotate to its natural position and become visible
};



const useStyles = makeStyles((theme) => ({
  root: {

  },
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: "hidden",
    backgroundColor: "#80A3AD",
    width: "100%",
    marginBottom: "-10%",
    left:'0px',
    position: "absolute",
    
    [theme.breakpoints.down('sm')]: {
      top: "-8px"
    }

  
  },
  featureInfo: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: '1.4rem',
    width: "80%",
    left: "10%",
    position: "relative",
    marginBottom: '2rem',
    textAlign: 'center',
    marginTop: '2rem',
    color: '#544C40',  // Text color for consistency
    fontWeight: 'bold'  // Make the text stand out
  },
  
  contentBox: {
    position: "relative",
    width: '50%',
    padding: theme.spacing(5),
    zIndex: 1, // Lower z-index value
    color: "white",
  },
  imageBox: {
    position: "relative", // Ensure it's relative or absolute for z-index to work
    top: '20px',
    width: '42vw',
    height: '40vw',
    overflow: 'hidden', 
    backgroundImage: `url(${ImageHand})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundPosition: 'center -1px',
    zIndex: 2, // Higher z-index value to make sure it's on top
    border: 'none',
    outline: 'none',
    '@media (max-width: 1200px)': {
      backgroundPosition: 'center -1px',
      top: "40px",
      width: '47vw',
      height: '52vw',
    },
  
    '@media (max-width: 868px)': {
      backgroundPosition: 'center -1px',
      top: "70px",
      width: '52vw',
      height: '66vw',
    },
    '@media (max-width: 800px)': {
      backgroundPosition: 'center -1px',
      top: "130px",
      width: '52vw',
      height: '70vw',
    },
    '@media (max-width: 650px)': {
      backgroundPosition: 'center -1px',
      top: "190px",
      width: '52vw',
      height: '70vw',
    },
    '@media (max-width: 600px)': {
      backgroundPosition: 'center -1px',
      top: "10px",
      width: '62vw',
      height: '80vw',
    }
  },
  youtubeDiv:{
    cursor: "pointer",
    position: "relative",
    // marginLeft: isMobile?"75%":"90%",
    // bottom: isMobile?"-10px":isTablet?"400px":"490px", 
    marginLeft: "95%",
    top: "32vw", 
    '@media (max-width: 1200px)': {
      top: "42vw", 
      marginLeft: "90%",
    },
    '@media (max-width: 870px)': {
      top: "52vw", 
    },
    '@media (max-width: 800px)': {
      top: "66vh", 
    },
    '@media (max-width: 600px)': {
      top: "90vh",
      right: "30px"
    },
    '@media (max-width: 500px)': {
      top: "85vh",
      right: "30px"
    },

  },
  benefitsSection: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    padding: theme.spacing(3),
  },
  doodle: {
    position: 'absolute',  // Absolute positioning to position it around your sections.
    width: '10vw',        // Width of the doodle. You can adjust it as required.
    zIndex: 0,            // To make sure it's on top
  },
  benefitBox: {
    maxWidth: "350px",
    padding: theme.spacing(2),
    backgroundColor: '#dee5e58c',
    color: '#544C40',  // Text color
    borderRadius: '20px',  // Bold corners
    transition: 'all 0.2s ease-in-out',
    width: "350px",
    [theme.breakpoints.down('md')]: {
      maxWidth: "1000px",
      width: "100%",
      left: "0%",
      position:  "relative",
      
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: "1000px",
      width: "100%",
      left: "0%",
      position:  "relative",
      marginTop:"20%"

      
    }
  },
  ctaSection: {
    marginTop: theme.spacing(5),
  },
  qaTitle: {
    fontFamily: '"Bungee", sans-serif',
    fontSize: "2.4rem",
    marginBottom: "30px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "2.0xrem",
      marginTop: "15px",
      marginLeft: "15px"

    }
  },
  qaText: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: "1.5rem",
    marginBottom: "50px",
    [theme.breakpoints.down('sm')]: {

      marginLeft: "15px",

    }
  },
  cardHeaderTitle: {
    fontFamily: '"Bungee", sans-serif',
    fontSize: "20px",
    [theme.breakpoints.down('sm')]: {
      marginBottom: "-20px"

    }
},
headerText: {
  fontFamily: '"Bungee", sans-serif',
  fontWeight: 'bold',
  fontSize: '2.5rem',
  marginTop: '5rem',
  marginBottom: '1rem',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.0rem',
  }
},
bodyText: {
  fontFamily: '"Roboto", sans-serif',
  fontSize: '1.3rem',
  marginBottom: '-2rem',
  textAlign: 'center',
  width: "65%",
  marginLeft: "17.5%",
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.1rem',
    width: "90%",
    marginLeft: "5%",
    marginBottom: '5rem',
  }
},


  ctaButton: {
    marginTop: theme.spacing(2),
    backgroundColor: '#FCF1D8',
    color: '#544C40',  // Text color
    border: '3px solid #544C40',  // Thicker border
    borderRadius: '12px',  // Bold corners
    boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.8), -8px -8px 16px rgba(0, 0, 0, 0.3)',
    padding: theme.spacing(1.5, 4),
    fontSize: '1.2rem',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: '#EDE4D0',  // Slight color shift on hover
      boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.8), -8px -8px 16px rgba(255, 255, 255, 0.1)',
    },
    '&:active': {
      boxShadow: 'inset 4px 4px 8px rgba(52, 47, 40, 0.25), inset -4px -4px 8px rgba(252, 241, 216, 0.9)',  // Pressed effect on active
    }
  },

  '@media (max-width: 600px)': {  // For screens smaller than 600px
    topSection: {
      flexDirection: 'column', // Stack items vertically
    },
    contentBox: {
      width: '100%',
      padding: theme.spacing(3), // Reduce padding for mobile
      textAlign: 'center' // Center content for mobile
    },
    imageBox: {
      width: '90vw',
      height: '95vw',
      marginTop: theme.spacing(2) // Add a bit of space between items
    },
    featureInfo: {
      width: "95%", // Make full width
      left: "2.5%", // Centering it
      fontSize: '1.2rem' // Adjust font size
    },
    // contentBox: {
    //   width: '100%',
    //   textAlign: 'center'
    // },
    bodyText: {
      width: "100%",
      marginLeft: "0%"
    },

    doodle: {
      display: 'none',  // Remove the doodles on small screens
    },
  }
}));

const LandingPage = ({ onUserLogin, user, handleOpenPopup, backendUrl }) => {
  const benefitBoxRef = useRef(null);
  const typographyRef = useRef(null);
  const typographyRef2 = useRef(null);
  const qaDivRef1 = useRef(null);
  const qaDivRef2 = useRef(null);
  const notepadRef = useRef(null);
  const brainRef = useRef(null);
  const navigate = useNavigate();
  const qaDivRef3 = useRef(null);
  const qaDivRef4 = useRef(null);

  const subRef = useRef();
  const ctaButtonRef = useRef(null);
  const classes = useStyles();
  const typewriterRef = React.useRef(null);


  const [hover, setHover] = useState(false);
  const videoURL = "https://www.youtube.com/watch?v=_vGiVOtNvRg";  // Replace VIDEO_ID with your video's ID


  const isInView = useInView(benefitBoxRef, { once: true })
  const isSubscribeInView = useInView(subRef, { once: true })
  const isQAInView1 = useInView(qaDivRef1,  { once: true })
  const isQAInView2 = useInView(qaDivRef2,  { once: true })
  const isQAInView3 = useInView(qaDivRef3,  { once: true })
  const isQAInView4 = useInView(qaDivRef4,  { once: true })
  const isNotepadInView = useInView(notepadRef)
  const isBrainInView = useInView(brainRef)
  const mainControls = useAnimation();

  useEffect(()=>{
    console.log(isInView)
    mainControls.start("visible")
  }, [isInView]);


const [imageX, setImageX] = useState('float');


const [openGooglePopoup, setOpenGooglePopup] = useState() 

const [clickedCard, setClickedCard] = useState(null);
const [index, setIndex] = useState(0);
const [showingTitle, setShowingTitle] = useState(true);
const theme = useTheme();
const isMobile = useMediaQuery('(max-width:600px)');
const isMobile2 = useMediaQuery('(max-width:900px)');
const isTablet = useMediaQuery('(max-width:1100px)');


const currentMessage = {
  // You can expand this object for different cards
  "AI": {
      personalLetterTitle: "AI Card Title",
      personalLetterBody: "AI Card body text that will type out."
  },
  "AI": {
    personalLetterTitle: "AI Card Title",
    personalLetterBody: "AI Card body text that will type out."
},
"AI": {
  personalLetterTitle: "AI Card Title",
  personalLetterBody: "AI Card body text that will type out."
},
}[clickedCard];

useEffect(() => {
  if (!currentMessage) return;

  const timer = setTimeout(() => {
      const currentText = showingTitle ? currentMessage.personalLetterTitle : currentMessage.personalLetterBody;

      if (index < currentText.length) {
          setIndex(index + 1);
      } else {
          if (showingTitle) {
              setShowingTitle(false);
              setIndex(0);
          } else {
              setClickedCard(null); // Reset after typing out body
          }
      }
  }, showingTitle ? 50 : 20);

  return () => clearTimeout(timer);
}, [index, currentMessage, showingTitle]);

const isTokenValid = () => {
  const jwtToken = localStorage.getItem("JWT_ACCESS_TOKEN");
  const tokenExpiration = localStorage.getItem("TOKEN_TIMESTAMP"); // Ensure you are setting this during login
  console.log(jwtToken)
  console.log('token expiration',tokenExpiration)
  return jwtToken && tokenExpiration && (Date.now() <= parseInt(tokenExpiration));
};

const handleButtonClickBuy = () => {
  setImageX("exitFrame"); 
  setTimeout(() => {
    setImageX("enterFrame");
    setTimeout(() => {
      setImageX("float");
    }, 1600);
  }, 600);
  console.log(isTokenValid())
  if (isTokenValid()) {
    navigate('/account');
  } else {
    handleOpenPopup();
  }
};

const handleButtonClickCTA = () => {
  console.log(isTokenValid())
  if (window.gtag) {
    window.gtag('event', 'button_click', {
      'cta': 'main',
    });

}
  setImageX("exitFrame"); 
  setTimeout(() => {
    setImageX("enterFrame");
    setTimeout(() => {
      setImageX("float");
    }, 1600);
  }, 600);
  if (isTokenValid()) {
    navigate('/upload');
  } else {
    handleOpenPopup();
  }
};



useEffect(() => {
  gsap.from(typographyRef.current, {
      autoAlpha: 0,
      y: 20,
      ease: 'none',
      scrollTrigger: {
          trigger: typographyRef.current,
          start: 'bottom bottom',
          toggleActions: 'play none none reverse',
      },
  });
}, []);

useEffect(() => {
  gsap.from(typographyRef2.current, {
      autoAlpha: 0,
      y: 20,
      ease: 'none',
      scrollTrigger: {
          trigger: typographyRef2.current,
          start: 'bottom bottom',
          toggleActions: 'play none none reverse',
      },
  });
}, []);




  const startTypewriterAnimation = () => {
    typewriterRef.current.startAnimation();
};


  return (
    <Container className={classes.root}>

<Grid 
style={{


}}
item>
      <Box className={classes.topSection}>
        <Box className={classes.contentBox} style={{ top:"-5vw" }}>
        <motion.div
      ref={typewriterRef}
      variants={slideUpFadeIn}
      initial="hidden"
      whileInView="visible"
      style={{ marginBottom: isMobile?"0px":"20px", marginTop: "20px" }} // or any styling you need
    >
  <Typography 
    style={{
      fontSize:isMobile? "2.5rem":isTablet?"2.8rem":"4.0rem",
      fontFamily: '"Bungee", sans-serif',
      marginBottom: isMobile?"1.7rem":"0px",
      letterSpacing: '-1.6px'
    }}
    variant="h2"
  >
    AI-Powered<br />
    Job Hunting!
  </Typography>
  <Typography 
    style={{
      fontSize:isMobile?"1.2rem":"1.6rem",
      fontFamily: '"Roboto", sans-serif',
    }}
    variant="h5"
  >
    Why search for jobs the hard way? Let our AI GPT-4 Chrome extension create cover letters and answer Job-Ad questions for you!
  </Typography>
</motion.div>


          <Button 
            style={{
                position: "relative",
                top: "20px",
                rotate: "4deg"
            }}
            variant="contained" 
            color="primary" 
            className={classes.ctaButton}

            onClick={handleButtonClickCTA}>
            Get Started For FREE
          </Button>
        </Box>
        <motion.div
          className={classes.imageBox}
          initial="initial"
          animate={imageX}
          variants={handAnimationVariants}
        ></motion.div>

        <img src={DoodleSVG} alt="Doodle" className={classes.doodle} style={{ right: '5%', top: '20%' }} />
        <img src={DoodleSVG2} alt="Doodle" className={classes.doodle} style={{ right: '90%', top: '83%',  width: '7vw',   
  height: 'auto', }} />

      <img src={DoodleSVG3} alt="Doodle" className={classes.doodle} style={{ right: '45%', top: '70%', width: '4vw', height: 'auto' }} />
      <img src={DoodleSVG4} alt="Doodle" className={classes.doodle} style={{ right: '85%', top: '15%', width: '8vw', height: 'auto' }} />
      <img
        src={DoodleSVG6}
        alt="Doodle"
        className={classes.doodle}
        style={{
          right: '55%',
          top: '4%',
          width: '3vw',
          height: 'auto',
          transform: 'rotate(45deg)', // Rotate by 45 degrees (you can change the angle as needed)
        }}
      />
      <img
        src={DoodleSVG7}
        alt="Doodle"
        className={classes.doodle}
        style={{
          right: '10%',
          top: '70%',
          width: '6vw',
          height: 'auto',
          transform: 'rotate(-75deg)', // Rotate by 45 degrees (you can change the angle as needed)
        }}
      />
            <img
        src={DoodleSVG9}
        alt="Doodle"
        className={classes.doodle}
        style={{
          right: '70%',
          top: '50%',
          width: 'vw',
          height: 'auto',
          zIndex: -1,
          transform: 'rotate(25deg)', // Rotate by 45 degrees (you can change the angle as needed)
        }}
      />

      </Box>
      <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => window.open(videoURL, "_blank")}
            style={{ }} // To change the cursor on hover
            className={classes.youtubeDiv}
        
        >
        <YouTubeIcon
            style={{
                position: "relative",
                fontSize: "70px",
                color: hover ? "red" : "white",

                zIndex: 2,
                transition: "transform 0.3s ease, color 0.3s ease",
                transform: hover ? "scale(1.07)" : "scale(1)" // scales the icon by 7% on hover
            }}
        />

        </div>

      </Grid>


      <div id="how_it_works_div" style={{position: "relative", top: "-100px"}}></div>
      <Box
      style={{  
        marginBottom: isMobile? "100px":null
      }}
      >
        <LandingBenifitComponent/>
      </Box>
      

      
      <Box className={classes.benefitsSection}
      style={{
        position: "relative",
        marginTop: isMobile?"210%":isMobile2?"70%":isTablet?"480px":"220px",

      }}
      >
        

          <Grid container spacing={3}>
           <Grid item xs={12} sm={12} md={4}>
          <div
              ref={benefitBoxRef}
              initial="hidden" 
              animate={{ 
                opacity: isInView ? 1 : 0,
                x: isInView ? 0 : -50
              }}
              transition={{
                duration: 0.5,
              }}
              whileHover={{ scale: 1.03 }}
              >

           
                
          <Card elevation={0} className={classes.benefitBox}>
            
            <img src={AISVG} alt="AI Icon" style={{ width: '22%',maxWidth: "76px" ,margin: '0 auto', display: 'block' }} />
            <CardHeader title="AI-Powered Efficiency" titleTypographyProps={{align: 'center', className: classes.cardHeaderTitle }} />
            <CardContent>
                <Typography align="center">Harness the
                <span style={{ fontWeight: 'bold' }}> power of GPT-4. </span>
                
                
                 Generate AI-crafted 
                 <span style={{ fontWeight: 'bold' }}> cover letters </span>
                 and answer 
                 <span style={{ fontWeight: 'bold' }}> job questions </span>
                directly from your CV,
                 <span style={{ fontWeight: 'bold' }}> simplifying </span>
                 your job search. Leverage cutting-edge AI technology to ensure your applications stand out in a crowded job market.
            </Typography>
            </CardContent>
        </Card>
        </div>


          </Grid>


          <Grid item xs={12} sm={12} md={4}>
               <div 
          ref={benefitBoxRef}
          initial="hidden" 
          animate={{ 
            opacity: isInView ? 1 : 0,
            y: isInView ? 0 : 50
          }}
          transition={{
            duration: 0.5,
          }}
          whileHover={{ scale: 1.03 }}
              >
            <Card   elevation={0} className={classes.benefitBox}>
              <img src={SpeedSVG} alt="AI Icon" style={{ width: isMobile?'16%':'20%',maxWidth: "66px", margin: '0 auto', display: 'block' }} />
              <CardHeader title="Rapid Job Outreach" titleTypographyProps={{align: 'center', className: classes.cardHeaderTitle}} />
              <CardContent>
                <Typography align="center">


                <span style={{ fontWeight: 'bold' }}> Accelerate </span>
                your job hunt with our AI-driven approach. 
                Tackle 
                <span style={{ fontWeight: 'bold' }}> multiple job ads </span>
                with ease, making the application journey 
                <span style={{ fontWeight: 'bold' }}> less tedious. </span>
                 Benefit from automation that not only 
                 <span style={{ fontWeight: 'bold' }}> speeds up </span>
                  the process but also crafts <span style={{ fontWeight: 'bold' }}> personalized </span> cover letters and answers for each position.
                </Typography>

              </CardContent>
            </Card>
            </div>
          </Grid>

           <Grid item xs={12} sm={12} md={4}>
          <div 
          ref={benefitBoxRef}
          initial="hidden" 
          animate={{ 
            opacity: isInView ? 1 : 0,
            x: isInView ? 0 : 50
          }}
          transition={{
            duration: 0.5,
          }}
          whileHover={{ scale: 1.03 }}
              >
            <Card   elevation={0} className={classes.benefitBox}>
              <img src={ApprovalSVG} alt="AI Icon" style={{ width: isMobile?'16%':'25%', maxWidth: "66px", margin: '0 auto', display: 'block' }} />
              <CardHeader title="Optimized Impressions" titleTypographyProps={{align: 'center', className: classes.cardHeaderTitle}} />
              <CardContent>
                <Typography align="center">The AI ensures your applications are 
                <span style={{ fontWeight: 'bold' }}> keyword-rich</span>
                , making you a top choice for hiring managers.
                Stand out with AI-optimized applications that
                <span style={{ fontWeight: 'bold' }}> align </span>
                perfectly 
                <span style={{ fontWeight: 'bold' }}> with job descriptions </span>
                and recruiter 
                <span style={{ fontWeight: 'bold' }}> expectations</span>

                
                </Typography>
              </CardContent>
            </Card>
            </div>
          </Grid>
        </Grid>

        <div
          id="faq_div"
          style={{
            position: "relative",
            top: "60px"
          }}
          >
          </div>
          <div
          style={{
            marginTop: "12%"
          }}
          >
            <FAQ/>
          </div>
        


      <div id="demo_div" style={{position: "relative", top: "50px"}}></div>
        <motion.div

          ref={typographyRef}
          variants={slideUpFadeIn}
          initial="visible"
          animate={"visible"}
          style={{ marginBottom: "0px", position: "relative", top:"-0px", marginTop: "15%" }}
        >
          <Typography className={classes.headerText}>
            Turn Job Applications into a Single Click!
          </Typography>
        </motion.div>

        <motion.div
          ref={typographyRef2}
          variants={scaleAndFadeIn}
          initial="visible"
          animate={"visible"}
          transition={{ delay: 0.7 }}
          style={{
            position: "relative",
            marginBottom: "30px"
          }}
          // delay it a bit so they don't animate in at the exact same time
        >
          <Typography className={classes.bodyText}>
          Tap into the <strong>GPT-4 Chrome extension</strong> for effortless applications. Let AI create <strong>Cover Letters</strong>, <strong> answer Job-Ad Questions</strong> and <strong>Enhance your CV</strong>. One click, tailored results, and your personal touch intact. Ready for a game-changing job search? <br></br>
          </Typography>
        </motion.div>
        {!isTablet && (
          <AnimationManager classes={classes} />
        )}
        


        {!isTablet && (
          <img src={DoodleSVG5} alt="Doodle" className={classes.doodle} style={{ right: '50%', top: '87%', width: '7vw', height: 'auto', zIndex: 0 }} />
        )}
        
        {/* <img src={BlobSVG} alt="AI Icon" style={{ width: '60%', margin: '0 auto', display: 'block',position:"absolute", top:"30%",left: "20%", zIndex: -1 }} />
       */}
      </Box>

      <Grid 
      style={{
        height: "70%",
      }}
      container >
        
        
     

      </Grid>
      <Grid container >
     


      </Grid>

      <div id="pricing_div" style={{position: "relative", top: "-100px"}}></div>
      <Box
      style={{
        marginTop: "0%"
      }}
      >

          <LandingPricingComponent
          handleButtonClick={handleButtonClickBuy}
          />
      </Box>


       
      <motion.div
      ref={subRef}
      initial="hidden" 
      animate={{ 
        opacity: isSubscribeInView ? 1 : 0,
        y: isSubscribeInView ? 0 : 50
      }}
      transition={{
        duration: 0.7,
        delay: 0.1,
        type: 'spring',
        stiffness: 700,
        damping: 20
        
      }}
      
      >
      <SubscriptionForm 
      backendUrl={backendUrl}
      />
      </motion.div>

    </Container>
  );
};

export default LandingPage;
