import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';
import { motion } from 'framer-motion';


const useStyles = makeStyles((theme) => ({
    paper: {
        width: 'calc(69vh * 2 / 3)',  // 2:3 aspect ratio based on the given vh
        height: '49vh',
        margin: '20px auto',
        padding: theme.spacing(3),
        backgroundColor: '#FCF1D8',
        color: '#544C40',
        border: '3px solid #544C40',
        boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.6), -8px -8px 16px rgba(255, 255, 255, 0.1)',
        borderRadius: '12px',
    },
    title: {
        fontSize: '2.2rem',
        marginBottom: theme.spacing(2),
    },
}));

const shakeAnimation = {
    initial: {},
    shake: {
        x: [-5, 5, -5, 5, -5, 5, -5, 5, 0],
        y: [5, -5, 5, -5, 5, -5, 5, -5, 0],
        transition: {
            duration: 0.4,
            ease: "easeInOut",
            times: [0, 0.2, 0.4, 0.6, 0.8, 1],
        },
    },
};



const TypewriterComponent = ({ data, currentIndex }) => {
    const classes = useStyles();

    const [showingTitle, setShowingTitle] = useState(true);
    const [index, setIndex] = useState(0);

    const currentMessage = data[currentIndex];

    useEffect(() => {
        setShowingTitle(true);
        setIndex(0);
    }, [currentIndex]);

    useEffect(() => {
        let timer;
    
        if (showingTitle && index === currentMessage.personalLetterTitle.length) {
            timer = setTimeout(() => {
                setShowingTitle(false);
                setIndex(0);
            }, 300);
        } else {
            const currentText = showingTitle ? currentMessage.personalLetterTitle : currentMessage.personalLetterBody;
            const delay = showingTitle ? 50 : 20; // set different delays for title and body
    
            if (index < currentText.length) {
                timer = setTimeout(() => {
                    setIndex(index + 1);
                }, delay);
            } else if (!showingTitle) { // This means we've finished displaying the body as well
                timer = setTimeout(() => {
                    setShowingTitle(true); // Restart the animation by showing the title again
                    setIndex(0);           // Start from the beginning
                }, 750);                  // You can adjust this delay to control how long it waits before restarting
            }
        }
    
        return () => clearTimeout(timer);
    }, [index, currentMessage, showingTitle]);
    

    return (
        <motion.div key={currentIndex} initial="initial" animate={currentIndex !== 0 ? "shake" : "initial"} variants={shakeAnimation}>
            <Card className={classes.paper}>
                <CardContent>
                    <Typography className={classes.title}>
                        {showingTitle ? currentMessage.personalLetterTitle.substring(0, index) : currentMessage.personalLetterTitle}
                    </Typography>
                    {!showingTitle && (
                        <Typography>
                            {currentMessage.personalLetterBody.substring(0, index)}<span>|</span>
                        </Typography>
                    )}
                </CardContent>
            </Card>
        </motion.div>
    );
}

export default TypewriterComponent;