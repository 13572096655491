import React from 'react';
import { Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Box, Divider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useMediaQuery } from '@material-ui/core';

export default function FAQ() {
  const isMobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const questionsAndAnswers = [
    {
      question: "🤖 How does GPT-4 improve my CV?",
      answer: "With <strong>GPT-4's</strong> capabilities, <strong>Link Letter Pro</strong> optimizes your CV content. From creating engaging summaries to making impactful adjustments, it ensures your CV shines—essentially at a click of a button."
    },
    {
      question: "✒️ How does Link Letter Pro streamline the job application?",
      answer: "<strong>Link Letter Pro</strong> transforms your job application with its algorithms. It instantly crafts <strong>tailored cover letters</strong>, and with a click, <strong>auto-answers job ad questions</strong>, making the entire process smooth and efficient."
    },    
    {
      question: "🚀 Why choose Link Letter Pro over others?",
      answer: "More than just cover letters, <strong>Link Letter Pro</strong> offers a seamless Chrome extension, auto-answers questions, and evolves based on feedback. It's the blend of simplicity and innovation that makes us unique."
    },
    {
      question: "📚 Can Link Letter Pro guide my CV?",
      answer: "Certainly! Apart from generating cover letters, <strong>Link Letter Pro</strong> offers AI-powered tips and insights to elevate your CV, all refined to perfection at a click."
    },
    {
      question: "📜 Why should cover letters be customized?",
      answer: "A tailored cover letter can be a game-changer. Studies show hiring managers favor customized letters, revealing your genuine passion and interest in the role."
    },
    {
      question: "🌐 Is a cover letter still relevant today?",
      answer: "Undoubtedly! In this digital age, a crafted cover letter remains pivotal. It reflects your character and fervor for the job, becoming an essential asset in your job-hunting toolkit."
    }
];

  

  return (
    <Box p={3}>
      <Typography 
      style={{
        fontFamily: '"Bungee", sans-serif',
        marginBottom: "2rem",  // reduced margin
        fontSize: isMobileView ? "1.6rem" : "2.6rem"  // font size changes based on device
      }}
      variant="h4" align="center" gutterBottom>
        Frequently Asked Questions
      </Typography>

      {questionsAndAnswers.map((qa, index) => (
        <ExpansionPanel
        elevation={0}
        style={{
            width: isMobileView ? "110%" : "60%",
            marginLeft: isMobileView ? "-5%" : "20%",
            backgroundColor: "transparent",
            marginBottom: "1rem"  // space between each FAQ item
        }}
        key={index}>
          <ExpansionPanelSummary
           expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" style={{
              fontWeight: "600"  // bold font for questions
            }}>
              {qa.question}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
          <Typography style={{ color: "#555" }} dangerouslySetInnerHTML={{ __html: qa.answer }}></Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </Box>
  );
}