import React, { useState, useEffect } from 'react';
import { Typography, Chip, Grid, Paper, Button, Tooltip, IconButton, Box } from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    backgroundColor: '#FFFFF',
    marginTop: "40px",
    width: "60%",
    position: "relative",
    left: "20%",
    color: '#544C40',
    border: '3px solid #544C40',
    borderRadius: '12px',
    boxShadow: '8px 8px 16px rgba(52, 47, 40, 0.25), -8px -8px 16px rgba(252, 241, 216, 0.9), 0px 0px 10px rgba(128, 128, 128, 0.7)',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.002)',
    },
  },
  header: {
    fontWeight: 600,
    marginBottom: theme.spacing(4)
  },
  subHeader: {
    color: '#777',
    marginBottom: theme.spacing(5)
  },
  paper: {
    margin: theme.spacing(2.5, 0),
    padding: theme.spacing(3.5),
    backgroundColor: '#FCF1D8',
    color: '#544C40',  // Text color
    border: '3px solid #544C40',  // Thicker border
    borderRadius: '12px',  // Bold corners
    boxShadow: '8px 8px 16px rgba(52, 47, 40, 0.25), -8px -8px 16px rgba(252, 241, 216, 0.9), 0px 0px 10px rgba(128, 128, 128, 0.7)',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.01)',
      boxShadow: '8px 8px 16px rgba(52, 47, 40, 0.85), -8px -8px 16px rgba(252, 241, 216, 0.9), 0px 0px 10px rgba(128, 128, 128, 0.7)',
    },
  },
  chip: {
    transition: 'all 0.3s',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)'
    }
  },
  saveButton: {
    backgroundColor: '#544C40',
    color: '#FCF1D8',
    padding: theme.spacing(1, 2),
    fontSize: '1rem',
    marginTop: theme.spacing(2.5)
  },
  selectedChip: {
    backgroundColor: '#544C40',  // Selected chip background
    color: '#FCF1D8'             // Selected chip text color
  },
  unselectedChip: {
    backgroundColor: '#FCF1D8',  // Unselected chip background
    color: '#544C40'             // Unselected chip text color
  }
}));


export default function SettingsPage({backendUrl}) {
  const isMobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const subsections = [
    { 
      name: 'Language Style', 
      key: 'languageStyle', 
      values: ['Original', 'Formal', 'Informal', 'Professional', 'Casual'],
      description: "Choose how formal or informal you'd like the language of the model's response to be.",
      details: {
        'Original': 'The default language style.',
        'Formal': 'Polite and respectful.',
        'Informal': 'Relaxed and conversational.',
        'Professional': 'Suited for a business setting.',
        'Casual': 'Laid-back tone.'
      }
    },
    { 
      name: 'Content Focus', 
      key: 'contentFocus', 
      values: ['Original', 'Achievements', 'Skills', 'Experience', 'Interests'],
      description: "Highlight what you'd like the model to focus on when drafting your content.",
      details: {
        'Original': 'Standard content focus.',
        'Achievements': 'Focus on major wins and milestones.',
        'Skills': 'Emphasizes on capabilities and proficiencies.',
        'Experience': 'Highlights work experience and past roles.',
        'Interests': 'Points out hobbies and passions.'
      }
    },
    { 
      name: 'Tone', 
      key: 'tone', 
      values: ['Original', 'Positive', 'Neutral', 'Enthusiastic', 'Serious'],
      description: "Set the emotional tone for the response. Would you prefer a positive, neutral, or serious reply?",
      details: {
        'Original': 'Standard emotional tone.',
        'Positive': 'Optimistic and uplifting.',
        'Neutral': 'Even-keeled without extreme emotion.',
        'Enthusiastic': 'Excited and energetic.',
        'Serious': 'Grave and earnest.'
      }
    }
  ];

  const initialState = subsections.reduce((acc, subsection) => {
    acc[subsection.key] = 'Original';
    return acc;
  }, {});


  const [selectedOptions, setSelectedOptions] = useState(initialState);

  // Load stored settings from localStorage when the component mounts
  useEffect(() => {
    const storedSettings = localStorage.getItem('settings');
    if (storedSettings) {
      setSelectedOptions(JSON.parse(storedSettings));
    }
  }, []);

  const handleToggleOption = (subsection, option) => {
    setSelectedOptions(prev => ({ ...prev, [subsection]: option }));
  };

  // Save settings to localStorage
  const handleSave = () => {
    console.log(backendUrl)
    console.log(selectedOptions)
    axios.post(backendUrl+'/update-settings/', {settings: selectedOptions})
      .then(response => {
        if(response.data.status === "success") {
          console.log("Settings updated successfully");
          // Save to localStorage
          localStorage.setItem('settings', JSON.stringify(selectedOptions));
        } else {
          console.error("Failed to update settings");
        }
      })
      .catch(error => console.error("Error:", error));
  };

  return (
    <Box 
    style={{
      width: isMobileView?"90%": null,
      left: isMobileView?"5%": null,
    }}
    className={classes.root}>
      <Typography variant="h5" className={classes.header}>
        Customize your cover letter style
      </Typography>
      <Typography variant="body1" className={classes.subHeader}>
        Adjust the settings below to influence how the AI model will generate content for your cover letters.
      </Typography>

      {subsections.map(subsection => (
        <Paper className={classes.paper} key={subsection.name} style={{ margin: '20px 0', padding: '30px', position: 'relative' }}>
          <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
            <Tooltip title={
              <div>
                {subsection.values.map(value => (
                  <Typography key={value} variant="body2">
                    {value}: {subsection.details[value]}
                  </Typography>
                ))}
              </div>
            }>
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </div>

          <Typography variant="h6" style={{ marginBottom: '10px', fontWeight: '500' }}>{subsection.name}</Typography>
          <Typography variant="body2" style={{ marginBottom: '20px', color: '#666' }}>
            {subsection.description}
          </Typography>
          <Grid container spacing={2}>
            {subsection.values.map(value => (
              <Grid key={value} item>
              <Chip
                  label={value}
                  clickable
                  className={selectedOptions[subsection.key] === value ? classes.selectedChip : classes.unselectedChip}
                  variant={selectedOptions[subsection.key] === value ? 'default' : 'outlined'}
                  onClick={() => handleToggleOption(subsection.key, value)}
                  style={{ transition: 'all 0.3s', cursor: 'pointer' }}
                  onMouseOver={(e) => e.currentTarget.style.boxShadow = '0px 2px 5px rgba(0, 0, 0, 0.1)'}
                  onMouseOut={(e) => e.currentTarget.style.boxShadow = ''}
              />
          </Grid>

            ))}
          </Grid>
        </Paper>
      ))}

      <Box display="flex" justifyContent="center">
        <Button 
          variant="contained"
          className={classes.saveButton}
          onClick={handleSave}
          startIcon={<TuneIcon />}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}




