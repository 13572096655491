import React, {useState} from 'react';
import { Dialog, IconButton, DialogContent, Button, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import GoogleLoginButton from './GoogleLoginButton';



const useStyles = makeStyles((theme) => ({
    root: {
        height: '69vh',
        backgroundColor: "transparent"
    },
    topSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: "hidden",
        backgroundColor: "#80A3AD",
        width: "100%",
        position: "relative",
        border: '3px solid #544C40',
        borderRadius: '12px',
        padding: theme.spacing(4),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: '#544C40',  // A color that contrasts with your background
        zIndex: 1000,  // Ensure it's on the top layer
    },
    
    featureInfo: {
        fontFamily: '"Roboto", sans-serif',
        fontSize: '1.2rem',
        width: "80%",
        textAlign: 'center',
        marginTop: '1rem',
        color: '#544C40',
        fontWeight: 'bold'
    },
    headerText: {
        fontFamily: '"Bungee", sans-serif',
        fontWeight: 'bold',
        fontSize: '3rem',
        marginBottom: '1rem',
        textAlign: 'center',
        color: '#FDFBE6',  // Making this lighter to pop more
    },
    bodyText: {
        fontFamily: '"Roboto", sans-serif',
        fontSize: '1.3rem',
        marginBottom: '3rem',
        textAlign: 'center',
        width: "80%",
        color: '#EDE4D0',  // Slightly lighter color for better contrast
    },
    ctaButton: {
        marginTop: theme.spacing(2),
        backgroundColor: '#FCF1D8',
        color: '#544C40',
        border: '3px solid #544C40',
        borderRadius: '12px',
        boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.8), -8px -8px 16px rgba(0, 0, 0, 0.3)',
        padding: theme.spacing(2, 5),   // Increased padding for more emphasis
        fontSize: '1.4rem',  // Slightly increased font size for emphasis
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
            backgroundColor: '#EDE4D0',
            boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.8), -8px -8px 16px rgba(255, 255, 255, 0.1)',
        },
        '&:active': {
            boxShadow: 'inset 4px 4px 8px rgba(52, 47, 40, 0.25), inset -4px -4px 8px rgba(252, 241, 216, 0.9)',
        }
    }
}));

export default function GoogleSignInPopup({ open, onClose, backendUrl, onUserLogin }) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false); // Add this line to manage the loading state


    return (
        <Dialog 
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    borderRadius: '12px'
                }
            }}
            open={open} 
            onClose={onClose}  // This will close the dialog when clicked outside
            aria-labelledby="alert-dialog-title" 
            aria-describedby="alert-dialog-description"
        >
            <IconButton 
                aria-label="close" 
                className={classes.closeButton} 
                onClick={onClose}  // This will close the dialog when the close button is clicked
            >
                <CloseIcon />
            </IconButton>
            <DialogContent className={classes.topSection}>

                            <h1 className={classes.headerText}>Let's Get Started!</h1>
                            <p className={classes.bodyText}>Log in with Google to unlock all features, free or premium.</p>
                            {
                    loading ? (
                        <CircularProgress style={{ color: '#FCF1D8' }} />  // This will set the color to red

                    ) : (
                        <>
                            <GoogleLoginButton
                                backendUrl={backendUrl}
                                onUserLogin={onUserLogin}
                                className={classes.ctaButton}
                                onClose={onClose}
                                setLoading={setLoading} // Pass the setLoading function to the child
                            />
                        </>
                    )
                }
            </DialogContent>
        </Dialog>

    );
}