import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Define styles for the component
const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
        backgroundColor: 'white',
        color: 'black',
        overflowY: 'auto',
        maxHeight: '80vh',
    },
    heading: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
    },
    subHeading: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    paragraph: {
        textAlign: 'justify',
        marginBottom: theme.spacing(1),
    },
}));

const PrivacyPolicyComponent = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
        <h1 className={classes.heading}>Privacy Policy</h1>
    
        <p className={classes.paragraph}>
            Welcome to LinkLetterPro, an innovative application designed to streamline the job application process using AI technology. Our service assists job seekers in creating tailored cover letters and responses to job advertisement queries by utilizing information from LinkedIn job ads and users' CVs. This privacy policy details our commitment to protecting your personal information and how we handle your data.
        </p>
    
        <h2 className={classes.subHeading}>Information Collection and Use</h2>
        <p className={classes.paragraph}>
            LinkLetterPro collects data necessary to perform its services, including but not limited to LinkedIn job advertisements and personal CVs submitted by users. This information is used exclusively to generate personalized cover letters and answer job-related questions. We ensure that the use of this data is strictly confined to the stated purposes and is not exploited for any other reasons.
        </p>
    
        <h2 className={classes.subHeading}>Data Storage and Security</h2>
        <p className={classes.paragraph}>
            The security of your personal data is a top priority for us. We employ advanced security measures to safeguard your information against unauthorized access, alteration, or destruction. Your data is stored securely and is accessible only to authorized personnel.
        </p>
    
        <h2 className={classes.subHeading}>Data Sharing and Disclosure</h2>
        <p className={classes.paragraph}>
            LinkLetterPro adheres to a strict policy of not selling or sharing your data with third parties. Your information is used solely for the purpose of providing our services. We may disclose your data only when required by law or to protect our rights.
        </p>
    
        <h2 className={classes.subHeading}>User Consent and Data Management</h2>
        <p className={classes.paragraph}>
            By using LinkLetterPro, you consent to the collection and use of your information as described in this policy. You retain full control over your data and can request updates, modifications, or deletion at any time.
        </p>
    
        <h2 className={classes.subHeading}>Data Retention and Deletion</h2>
        <p className={classes.paragraph}>
            We retain your personal data only as long as necessary to provide you with our services. Upon your request to delete your account or data, we will ensure that all your information is permanently removed from our systems.
        </p>
    
        <h2 className={classes.subHeading}>Changes to Our Privacy Policy</h2>
        <p className={classes.paragraph}>
            LinkLetterPro reserves the right to modify this privacy policy at any time. Any changes will be communicated through our application or via email, as appropriate.
        </p>
    
        <h2 className={classes.subHeading}>Contact Us</h2>
        <p className={classes.paragraph}>
            If you have any questions or concerns about our privacy practices, please feel free to contact us.
        </p>
    </div>
    
    );
}

export default PrivacyPolicyComponent;
