import React from 'react';
import { Card, CardHeader, CardContent, Typography, Button, Grid, makeStyles } from '@material-ui/core';
import { motion } from 'framer-motion';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const useStyles = makeStyles(theme => ({
    centeredContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    benefitBox: {
        maxWidth: "350px",
        maxheight: "540px",
        padding: theme.spacing(2),
        backgroundColor: 'white',
        color: '#544C40', 
        border: '3px solid #544C40', 
        borderRadius: '12px',
        boxShadow: '8px 8px 16px rgba(52, 47, 40, 0.25), -8px -8px 16px rgba(252, 241, 216, 0.9)',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
            backgroundColor: '#faf9f0',
            boxShadow: '4px 4px 8px rgba(52, 47, 40, 0.25), -4px -4px 8px rgba(252, 241, 216, 0.9)',
        },
        '&:active': {
            boxShadow: 'inset 4px 4px 8px rgba(52, 47, 40, 0.25), inset -4px -4px 8px rgba(252, 241, 216, 0.9)',
        }
    },
    cardHeaderTitle: {
        fontFamily: '"Bungee", sans-serif',
        fontSize: "20px",
        fontWeight: 600,
    },
    ctaButton: {

        backgroundColor: '#bbfac4',
        color: '#544C40',  // Text color
        border: '3px solid #544C40',  // Thicker border
        borderRadius: '12px',  // Bold corners
        boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.8), -8px -8px 16px rgba(0, 0, 0, 0.3)',
        padding: theme.spacing(1.5, 4),
        fontSize: '0.8rem',
        marginBottom: "10px",
        marginTop: "5px",
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          backgroundColor: '#6cbd78',  // Slight color shift on hover
          color: '#FFFFFF',  // Text color
          boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.8), -8px -8px 16px rgba(255, 255, 255, 0.1)',
        },
        '&:active': {
          boxShadow: 'inset 4px 4px 8px rgba(52, 47, 40, 0.25), inset -4px -4px 8px rgba(252, 241, 216, 0.9)',  // Pressed effect on active
        }
      }
}));

const letterVariants = {
    hidden: { opacity: 0, scale: 0.8, y: -20 },
    visible: { opacity: 1, scale: 1, y: 0 }
};

export default function LetterPack({ onSelect }) {
    const classes = useStyles();

    const packs = [
        { letters: 25, price: 5, savings: 0, color: "#cd7f32" },
        { letters: 75, price: 10, savings: 50, color: "#C0C0C0" },
        { letters: 200, price: 20, savings: 60, color: "#FFD700" }
      ];
      

    return (
        <Grid container spacing={3}>
            {packs.map((pack, index) => (
                <Grid key={index} item xs={12} sm={4}>
                    <motion.div 
                        variants={letterVariants} 
                        initial="hidden" 
                        animate="visible"
                        whileHover={{ scale: 1.03 }}
                    >
                        <Card className={classes.benefitBox}>
                            <MailOutlineIcon style={{ fontSize: '3.2rem', margin: '0 auto', display: 'block', color: pack.color }} />
                            <CardHeader 
                                title={`${pack.letters} Credits`} 
                                titleTypographyProps={{align: 'center', className: classes.cardHeaderTitle }} 
                                subheader={
                                    <div>
                                    {`🦾 GPT-4 powered AI outputs`}
                                    <br />
                                    {`✉️ ${pack.letters} cover letters`}
                                    <br />
                                    {`📌 ${pack.letters} Job-Ad question answers`}
                                    <br />
                                    {`📝 ${pack.letters} AI CV enhancements`}
                                  </div>
                                }  // Add this line to display the plan description
                            />
                            <CardContent className={classes.centeredContent}>
                                <Typography align="center" style={{marginTop: "-20px", fontWeight: "bold"}}>{`Price: $${pack.price}`}</Typography>
                                {pack.savings > 0 && 
                                <Typography align="center" style={{color: 'green'}}>
                                    {`Save ${pack.savings}% per credit!`}
                                </Typography>}
                                <Button className={classes.ctaButton} onClick={() => onSelect(pack)}>
                                    Buy Now
                                </Button>
                            </CardContent>
                        </Card>
                    </motion.div>
                </Grid>
            ))}
        </Grid>
    );
}
