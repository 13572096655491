import React, { useState } from 'react';
import { Typography, Button, Dialog, DialogContent, Box, Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import { useTheme, useMediaQuery } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(2),
    backgroundColor: "transparent"
  },
  topSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderRadius:"3px",
    border: '3.4px solid #544C40',
    backgroundColor: "#80a3ad85"
  },
  textContainer: {
    padding: theme.spacing(2),
  },
  title: {
    fontFamily: '"Bungee", sans-serif',
    fontSize: "2.2rem",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.6rem",
    }
  },
  ctaButton: {
    margin: theme.spacing(1),
    fontSize: "12px",
    backgroundColor: '#fcf1de',
    color: '#544C40',
    border: '1.4px solid #544C40',
    borderRadius: '12px',
    '&:hover': {
      backgroundColor: '#EDE4D0',
    },
    '&:active': {
      backgroundColor: '#f2c0c0',
    }
  },
  closeButton: {
    margin: theme.spacing(1),
    backgroundColor: '#fcf1de',
    border: '1.4px solid #544C40',
    borderRadius: "10px",
    color: '#544C40',
    '&:hover': {
      backgroundColor: '#EDE4D0',
    }
  },
  benefitBox: {
    marginBottom: theme.spacing(4),
    width: "100%",
    color: '#544C40',
  },
  subText: {
    fontFamily: '"Roboto", sans-serif',
    marginBottom: theme.spacing(2),
    fontSize: "1.2rem",
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.0rem",
    }
  },
  video: {
    width: '100%',
    height: '350px',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      marginLeft:"10%",
      height: "120%"
    }
  }
}));

function TutorialDialog(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      maxWidth="md"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={classes.topSection}>
        <Grid container className={classes.root}>
          <Grid item xs={12} md={4} className={classes.textContainer}>
            <Box my={4} className={classes.benefitBox}>
              <Typography variant="h6" className={classes.title}>
                Tutorial
              </Typography>
              <Typography className={classes.subText} variant="body1">
                <strong>1. </strong>
                <Link href="https://chrome.google.com/webstore/detail/link-letter-pro/hggckppbcbgancgnmnpdclipioalfhgo?hl=sv&authuser=0&fbclid=IwAR0r7hoxR6TQhrLdLqxN4jgBQOQSeWvlUssI4dLoxhVCxJ-AvLXg_41Z1dw" color="secondary" target="_blank" rel="noopener noreferrer">Install our Chrome Extension</Link>.
              </Typography>
              <Typography className={classes.subText} variant="body1">
                <strong>2. </strong>Upload your CV:
                <Button className={classes.ctaButton} variant="contained" color="primary" size="small" startIcon={<CloudUploadIcon />} >
                  Upload CV
                </Button>
              </Typography>
              <Typography className={classes.subText} variant="body1">
                <strong>3. </strong>Go to LinkedIn, click on the Chrome Extension, Sync with Job-Ad
              </Typography>
              <Typography className={classes.subText} variant="body1">
                <strong>4. </strong>Now the AI can Generate Cover letters and Answer Job-Ad questions for you.
              </Typography>
            </Box>
          </Grid>
          <Grid 
          style={{
            alignItems: "center",
            display: "flex"
          }}
          item md={8} xs={12}>
            <iframe className={classes.video} src="https://www.youtube.com/embed/_vGiVOtNvRg" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Grid>
        </Grid>
        <Button className={classes.closeButton} onClick={props.onClose} color="primary">
          Close
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default TutorialDialog;
