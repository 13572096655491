import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, Grid } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';

import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core'; 
import SpeedEmailSVG from '../../svg/speed_email.svg'
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { useNavigate } from 'react-router-dom'; // import the hook

const TOKEN_VALIDITY_DURATION = 1 * 60 * 60 * 1000; 

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: '#faf7ed', // Neutral color for neumorphism
        position: "fixed",
        top: "0px",
        zIndex: 10,
        boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.3), -8px -8px 16px rgba(255, 255, 255, 0.4)', // Adjusted shadow for a more neumorphic effect
        borderBottom: '3px solid rgba(0, 0, 0, 1)',

 // Adding some rounded corners to fit the neumorphic design
    }
    ,
    textLink: {
        fontSize: "1.1rem",
        transition: "color 0.2s ease, text-shadow 0.2s ease, transform 0.2s ease",
        transitionDelay: "0.1s",
        '&:hover': {
            color: "#68636e",
            transform: "scale(1.085)", // Scales the link slightly larger on hover
        }    
    },
       
    title: {
        fontFamily: '"Roboto", sans-serif',
        fontSize: "1.2rem",
        fontWeight: "bold",
        letterSpacing: '-0.07em',
        lineHeight: 1.0,
        color: "#544C40" // Darker color for text,
    },
    link: {
        textDecoration: 'none',
        color: '#333', // Darker color for text
        fontFamily: '"Roboto", sans-serif',

    },
    selectedButton: {
        margin: theme.spacing(0, 1),
        fontSize: "15px",
        backgroundColor: 'rgb(226, 207, 252)',
        border: '2px solid #544C40',
        color: '#544C40',  // Darker color for text consistency

        borderRadius: '10px',
        boxShadow: '8px 8px 16px rgba(52, 47, 40, 0.1), -8px -8px 16px rgba(252, 241, 216, 0.4)', // Neumorphic shadow
        '&:hover': {
            backgroundColor: 'rgb(185, 157, 224)',

            boxShadow: '4px 4px 8px rgba(52, 47, 40, 0.1), -4px -4px 8px rgba(252, 241, 216, 0.4)'
        },
        '&:active': {
            boxShadow: 'inset 4px 4px 8px rgba(52, 47, 40, 0.25), inset -4px -4px 8px rgba(252, 241, 216, 0.4)' // Pressed effect on active
        }
    },
    button: {
        margin: theme.spacing(0, 1),
        fontSize: "15px",
        color: '#544C40',  // Darker color for text consistency

        borderRadius: '10px',

        '&:hover': {
            backgroundColor: '#e5d7f7',
            boxShadow: '4px 4px 8px rgba(52, 47, 40, 0.1), -4px -4px 8px rgba(252, 241, 216, 0.9)'
        },
        '&:active': {
            boxShadow: 'inset 4px 4px 8px rgba(52, 47, 40, 0.25), inset -4px -4px 8px rgba(252, 241, 216, 0.9)' // Pressed effect on active
        }
    }
    ,
    svgLogo: {
        marginLeft: theme.spacing(1),
        height: '2rem', 
        width: 'auto',
        position: "absolute",
        top: "14px",
        left: "2rem"
    }
}));

const AppBarComponent = ({ user, handleOpenPopup, handleOpenTutorial}) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const location = useLocation();
    const [selectedButton, setSelectedButton] = useState('');

    const scrollToTop = () => {
        scroll.scrollToTop();
    };
    

    useEffect(() => {
        const jwtToken = localStorage.getItem("JWT_ACCESS_TOKEN");
        const tokenTimestamp = localStorage.getItem("TOKEN_TIMESTAMP");
        console.log('timestamp token: ', tokenTimestamp)

        if (jwtToken && tokenTimestamp) {
            const currentTime = Date.now();
            console.log('timestamp now: ', tokenTimestamp)

            if (currentTime - parseInt(tokenTimestamp) > TOKEN_VALIDITY_DURATION) {
                // Token is too old, clear it and prompt the user to log in again
                localStorage.removeItem("JWT_ACCESS_TOKEN");
                localStorage.removeItem("TOKEN_TIMESTAMP");
            }
        }
    }, [handleOpenPopup]);
    
    const isTokenValid = () => {
        const jwtToken = localStorage.getItem("JWT_ACCESS_TOKEN");
        const tokenTimestamp = localStorage.getItem("TOKEN_TIMESTAMP");
        return jwtToken && tokenTimestamp && (Date.now() - parseInt(tokenTimestamp) <= TOKEN_VALIDITY_DURATION);
    };

    const handleLinkClick = (e, route) => {
        
        if (route === 'install') {  // Check if the route is for tutorial
            handleOpenTutorial();
            window.open('https://chromewebstore.google.com/detail/link-letter-pro/hggckppbcbgancgnmnpdclipioalfhgo?hl=sv&fbclid=IwAR0r7hoxR6TQhrLdLqxN4jgBQOQSeWvlUssI4dLoxhVCxJ-AvLXg_41Z1dw', '_blank');
            e.preventDefault();  // prevent the default navigation action
            return;
        }
        
        if (route === 'tutorial') {  // Check if the route is for tutorial
            handleOpenTutorial();
            e.preventDefault();  // prevent the default navigation action
            return;
        }
        if (!isTokenValid()) {
            // If token is not valid, remove JWT token and its timestamp and show login popup
            localStorage.removeItem("JWT_ACCESS_TOKEN");
            localStorage.removeItem("TOKEN_TIMESTAMP");
            handleOpenPopup();
            
            e.preventDefault();  // prevent the default navigation action
            return;
        }

        setSelectedButton(route);
        if (route === 'upload' && user) {
            // If the user is already logged in and clicks "Try for free", redirect to a different route
            // Replace '/dashboard' with the actual route you want to redirect to

            navigate('/upload')
            e.preventDefault(); // Prevent default Link behavior
        } else {
            // Proceed with setting the selected button for other routes
            setSelectedButton(route);
        }
    };
const buttons = [
    { route: '/upload', label: 'Try for free', icon: null, scrollId: null },
    { route: null, label: 'FAQ', icon: null, scrollId: 'faq_div' },
    // add more buttons here...
];

    const isLandingPage = location.pathname === '/';
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 
    const isMobile2 = useMediaQuery(`(max-width:400px)`); // 2. Setup hooks to detect screen size.
    const isTablet = useMediaQuery(`(max-width:1150px)`); 
    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Link to="/" className={classes.link}>
                            <Typography
                               onClick={(e) => handleLinkClick(e, '')}

                                className={classes.title} variant="h7" color="inherit"
                                style={{
                                    fontWeight: "bold",
                                    letterSpacing: '-0.01em',
                                    fontSize: isTablet?"1.3rem":null
                                }}
                                >
                                Link Letter Pro
                            </Typography>

                            {!isMobile &&(
                     <img
                            
                     style={{
                         top:isMobile?"13px":"13px",
                         marginLeft: isMobile?"104px":"114px",

                     }}
                     src={SpeedEmailSVG} alt="Speed Email Logo" className={classes.svgLogo} />

                            )}
       
                            
                        </Link>
                    </Grid>
                    {!isLandingPage && (
                              <Grid item>
                              {[
                                  { route: '/', label: 'Home', icon: <HomeIcon /> },
                                  { route: '/upload', label: 'Upload CV', icon: <CloudUploadIcon /> },
                                  { route: '/tutorial', label: 'Tutorial', icon: <HelpOutlineIcon /> },
                                  { route: '/install', label: 'Install Extension', icon: <SystemUpdateIcon /> },
                                  { route: '/settings', label: 'Settings', icon: <SettingsIcon /> },
                                  { route: '/account', label: 'Account', icon: <AccountCircleIcon /> }
                              ].filter(item => !(isTablet && item.label === 'Tutorial')) // Exclude 'Tutorial' if isTablet is true
                               .map(item => (
                                <Link key={item.route} to={item.route} className={classes.link}>
                                    {isMobile ? (
                                        <span 
                                            style={{ marginLeft: isMobile2?"7px":"12px", marginRight: isMobile2?"7px":"12px" }}
                                            onClick={(e) => handleLinkClick(e, item.route.substring(1))}
                                        >
                                            {item.icon}
                                        </span>
                                    ) : (
                                        <Button
                                            startIcon={item.icon}
                                            color="inherit"
                                            className={selectedButton === item.route.substring(1) ? classes.selectedButton : classes.button}
                                            onClick={(e) => handleLinkClick(e, item.route.substring(1))}
                                        >
                                            {item.label}
                                        </Button>
                                    )}
                                </Link>
                            ))}

                        </Grid>
                    )}
                         {isLandingPage && (
                            <Grid item>
                            {[
                                { route: '/upload', label: 'Try for free', icon: null },
                            ].map(item => (
                                <Link key={item.route} to={null}
                                
                                className={item.label =="Try for free"? classes.link : null}>
                                    {isMobile ? (
                                        <span 
                                        style={{
                                            marginLeft: "10px",
                                            marginRight: "10px"
                                        }}
                                        onClick={(e) => handleLinkClick(e, item.route.substring(1))}
                                        >
                                        {item.icon}
                                        <Button
                                        className={classes.selectedButton}
                                        onClick={(e) => handleLinkClick(e, '/upload')}
                                        >TRY FOR FREE</Button>
                                        </span>
                                         ) : (
                                        <div>
                                        <Grid 
                                        container direction="row" alignItems="center" spacing={3}>

                                        <Grid item>
                                                <ScrollLink 
                                                
                                                    to="how_it_works_div" // Adjust the ID if it's not pricing_div
                                                    smooth={true} 
                                                    duration={500}
                                                    className={classes.link}
                                                >
                                                    <Typography
                                                        className={classes.textLink}
                                                        style={{  letterSpacing: '-0.01em' }}
                                                        variant="h6" color="inherit"
                                                    >
                                                        How it works
                                                    </Typography>
                                                </ScrollLink>
                                            </Grid>

                                            <Grid item>
                                                <ScrollLink 
                                                    to="faq_div"  
                                                    smooth={true} 
                                                    duration={500}
                                                    className={classes.link}
                                                >
                                                    <Typography
                                                        className={classes.textLink}
                                                        style={{ letterSpacing: '-0.01em' }}
                                                        variant="h6" color="inherit"
                                                    >
                                                        FAQ
                                                    </Typography>
                                                </ScrollLink>
                                            </Grid>

                                            <Grid item>
                                                <ScrollLink 
                                                    to="demo_div"  
                                                    smooth={true} 
                                                    duration={500}
                                                    className={classes.link}
                                                >
                                                    <Typography
                                                        className={classes.textLink}
                                                        style={{ letterSpacing: '-0.01em' }}
                                                        variant="h6" color="inherit"
                                                    >
                                                        Demo
                                                    </Typography>
                                                </ScrollLink>
                                            </Grid>

                                            <Grid item>
                                                <ScrollLink 
                                                    to="pricing_div" // Adjust the ID if it's not pricing_div
                                                    smooth={true} 
                                                    duration={500}
                                                    className={classes.link}
                                                >
                                                    <Typography
                                                        className={classes.textLink}
                                                        style={{  letterSpacing: '-0.01em'}}
                                                        variant="h6" color="inherit"
                                                    >
                                                        Pricing
                                                    </Typography>
                                                </ScrollLink>
                                            </Grid>
                                  
                                            <Grid 
                                            style={{
                                            }}
                                            item>
                                            <Button
                                            startIcon={item.icon}
                                            color="inherit"
                                            className={selectedButton === item.route.substring(1) ? classes.selectedButton : classes.button}
                                            onClick={(e) => handleLinkClick(e, item.route.substring(1))}
                                            style={{
                                                margin: theme.spacing(0, 1),
                                                fontSize: "15px",
                                                color: '#544C40', // Darker color for text consistency
                                                border: '2px solid #544C40',
                                                backgroundColor: "#e2cffc",
                                                borderRadius: '10px',
                                                transition: 'transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease', // Smooth transition for hover effects
                                                '&:hover': {
                                                backgroundColor: '#ffeb3b', // Yellowish background on hover
                                                transform: 'scale(1.25)', // Scales the button up to 105% of its size
                                                boxShadow: '1 0 15px rgba(255, 235, 59, 0.5)', // Yellowish glow effect
                                                },
                                                '&:active': {
                                                boxShadow: 'inset 4px 4px 8px rgba(52, 47, 40, 0.25), inset -4px -4px 8px rgba(252, 241, 216, 0.4)' // Pressed effect on active
                                                },
                                            }}
                                        >
                                            {item.label}
                                        </Button>
                                            </Grid>
                                    
                         
                                        </Grid>
                                    </div>
                                    
                                )}
                            </Link>
                        ))}
                        </Grid>
                    )}
                 
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

export default AppBarComponent;