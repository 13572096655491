import React, { useState, useEffect } from 'react';
import {
    Card, CardContent, Typography, TextField, Grid, Button, Dialog, DialogActions, DialogContent, FormGroup, FormControlLabel, Switch,
    DialogContentText, DialogTitle, IconButton
} from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import CVUploader from './CVUploader';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import { CircularProgress } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';


import { motion } from 'framer-motion';
import { useMediaQuery } from '@material-ui/core';

const CACHE_KEY = "cached_cvs";



const useStyles = makeStyles({
    card: {
        backgroundColor: '#FCF1D8',
        border: '3px solid #544C40',
        borderRadius: '8px',
        boxShadow: '8px 8px 16px rgba(52, 47, 40, 0.25), -8px -8px 16px rgba(252, 241, 216, 0.9)',
        padding: '16px',
        marginBottom: '20px',

    },
    tooltip: {
        fontSize: "1.8rem", // Adjust as needed for desired size
    },
    infoIcon: {
        position: 'absolute',
        right: '20px',
        color: '#544C40',
    },
    boltIcon: {
        position: 'absolute',
        right: '20px',
        color: '#544C40',
    },
    description: {
        marginBottom: '20px',
        color: '#544C40',
        lineHeight: 1.5,
    },
    
    title:{
        fontFamily: '"Bungee", sans-serif',
        fontSize: "2rem"
    },
    toggleButton: {
        width: '160px',
        height: '40px',
        borderRadius: '8px',
        backgroundColor: '#FCF1D8',
        border: '2px solid #544C40',
        '&:hover': {
            backgroundColor: '#E5DCCE',
        },
        textDecoration: 'underline',
    },
    actionButton: {
        margin: '20px 10px 0',
        borderRadius: '8px',
        backgroundColor: '#f0e2c2',
        border: '2px solid #544C40',
        '&:hover': {
            backgroundColor: '#E5DCCE',
        },
    },
    actionButtonEnhance: {
        margin: '20px 10px 0',
        borderRadius: '8px',
        backgroundColor: '#f0e2c2',
        fontSize: '1.1rem',
        border: '2px solid #544C40',
        '&:hover': {
            backgroundColor: '#E5DCCE',
        },
    },
    closeButton: {
        position: 'absolute',
        right: 8,
        top: 8,
    },
    dialog: {
        borderRadius: '8px',
    },
    dialogTitle: {
        fontWeight: "bold",
        color: '#544C40',
    },
    dialogContentText: {
        color: '#544C40',
    },
    typography: {
        color: '#544C40',
        textDecoration: 'underline',
    }
});

const CvDisplayComponent = ({ cvData: initialCvData, backendUrl, user}) => {
    const [cvData, setCvData] = useState(initialCvData);
    const [selectedCV, setSelectedCV] = useState(cvData.length ? cvData[0] : { title: '', content: '' });
    const [isUploadMode, setIsUploadMode] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [showReplaceCVPopup, setShowReplaceCVPopup] = useState(false);
    const isMobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false);
    const [enhancedTips, setEnhancedTips] = useState('');
    const [intro, setIntro] = useState('');
    const [listItems, setListItems] = useState([]);
    const [enhancedCV, setEnhancedCV] = useState()
    const [enhancedCVContent, setEnhancedCVContent] = useState('')
    const [showCVAddedPopup, setShowCVAddedPopup] = useState(false);
    const [isFileMode, setIsFileMode] = useState(true);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [filename, setFilename] = useState(null);

    const [editableEnhancementTips, setEditableEnhancementTips] = useState('');

    const handleFileUpload = async () => {
        console.log("Upload")
        console.log(uploadedFiles.length)
        if (uploadedFiles.length === 0) return;
    
        const formData = new FormData();
        formData.append('file', uploadedFiles[0]);  // Assuming only one file is uploaded
        // Add the additional data to the formData
        formData.append('google_id', user.userId);
        formData.append('title', selectedCV.id);


        try {
            const response = await axios.post(backendUrl+'/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('File uploaded successfully:', response.data);
            setUploadedFiles([]);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    
    const onDrop = React.useCallback((acceptedFiles) => {
        setUploadedFiles(acceptedFiles);
        // Do something with the accepted files
        console.log(acceptedFiles);
        // For example, to read the file as text:
        setFilename(acceptedFiles[0].name);
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const fileContent = event.target.result;
            setSelectedCV(prevState => ({ ...prevState, content: fileContent }));
        };
        reader.readAsText(file);
    }, []);
    
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: ".txt, .doc, .docx, .pdf"
    });
    // This function updates the editable enhancement tips
    const handleTextFieldChange = (event) => {
        setEnhancedCVContent(event.target.value);
    };

    const classes = useStyles();

    const updateLocalStorage = (data) => {
        localStorage.setItem(CACHE_KEY, JSON.stringify(data));
    };

    useEffect(() => {
        setCvData(initialCvData);
        if (initialCvData.length) {
            setSelectedCV(initialCvData[0]);
        } else {
            setSelectedCV({ title: '', content: '' });
        }

        updateLocalStorage(initialCvData);
    }, [initialCvData]);


    useEffect(() => {
        setCvData(initialCvData);
        if (initialCvData.length) {
            setSelectedCV(initialCvData[0]);
        } else {
            setSelectedCV({ title: '', content: '' });
        }

        updateLocalStorage(initialCvData);
    }, [initialCvData]);

    const handleToggle = (event, newIndex) => {
        if (newIndex !== null && newIndex !== undefined) {
            setSelectedCV(cvData[newIndex]);
        }
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(enhancedCVContent)
            .then(() => {
                // Optional: You can show a notification or toast that the text has been copied successfully.
                console.log('Text copied to clipboard');
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };
    
    const handleCopyToClipboardTips = () => {
        navigator.clipboard.writeText(intro)
            .then(() => {
                // Optional: You can show a notification or toast that the text has been copied successfully.
                console.log('Text copied to clipboard');
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };
    

    const handleNewCVClick = () => {
        setSelectedCV({ title: '', content: '' });
    };


    const handleClickEnhance = async() => {
        try {
            setLoading(true);
            const response = await axios.post(backendUrl+'/enhance-cv/', {
                google_id: user.userId,
                cv_id: selectedCV.id,
            });
    
            console.log('Received Tips:', response.data.tips); // Log the received data
            
            // Split string on number-dot pattern
            let parts = response.data.tips.split(/(\d+\.\s)/).filter(Boolean);
    
            // Reconstruct the list items with the number
            let listItems = [];
            for (let i = 0; i < parts.length; i++) {
                if (parts[i].match(/^\d+\.\s/)) {
                    listItems.push(parts[i] + (parts[i+1] || ''));
                    i++; // skip next part which is already appended
                }
            }
    
            const introIndex = parts.indexOf(listItems[0]); // Index of the first list item
            const intro = parts.slice(0, introIndex).join("").trim();
    
            setIntro(response.data.tips);
            setListItems(listItems);
    
            console.log('Intro:', intro);
            console.log('List Items:', listItems);
            setEnhancedTips('2332')
    
            setLoading(false);
        } catch (error) {
            console.error('Error updating/adding CV:', error);
            setLoading(false);
        }
    };
    
    const handleApplyEnhancement = async() => {
        try {
            setLoading(true);
    
            // Join the list items into a single string
            const tipsString = listItems.join(" ");
            setListItems([])
            // Send this consolidated tipsString to the backend
            const response = await axios.post(backendUrl+'/apply-enhancement/', {
                google_id: user.userId,
                cv_id: selectedCV.id,
                enhancement_tips: tipsString,
            });
    
            console.log('Received Enhanced CV:', response.data.enhanced_cv); 
            setEnhancedCVContent(response.data.enhanced_cv);
            console.log(enhancedCVContent)
            
            setLoading(false);
        } catch (error) {
            console.error('Error applying CV enhancement:', error);
            setLoading(false);
        }
    };
    
    
    const handleReplaceCV = async (cvIdToReplace) => {
        // replace the CV with ID cvIdToReplace with the new CV
        // Here, you can integrate the logic for replacing the CV in your database/API
        // Close the replace popup after the operation
        setShowReplaceCVPopup(false);
    };
    const handleUpdateClickEnhance = async () => {
        console.log("send CV",{user_id: user.userId,
            title: selectedCV.title,
            content: selectedCV.content
        })
        try {

            const response = await axios.post(backendUrl+'/cvs/', {
                user_id: user.userId,
                title: selectedCV.title,
                content: selectedCV.content
            });

            if (response.data.message === "CV created successfully.") {
                const newCV = {
                    id: response.data.cvId,
                    title: selectedCV.title,
                    content: selectedCV.content
                };

                if (cvData.length >= 3) {
                    setShowReplaceCVPopup(true);
                } else {
                    setCvData(prevCvData => [newCV, ...prevCvData]);
                    setSelectedCV(newCV);
                }
            }
        } catch (error) {
            console.error('Error updating/adding CV:', error);
        }
    };

    const handleUpdateClick = async () => {
        console.log("send CV",{user_id: user.userId,
            title: selectedCV.title,
            content: selectedCV.content
        })
        try {
            const response = await axios.post(backendUrl+'/cvs/', {
                user_id: user.userId,
                title: selectedCV.title,
                content: selectedCV.content
            });

            if (response.data.message === "CV created successfully.") {
                const newCV = {
                    id: response.data.cvId,
                    title: selectedCV.title,
                    content: selectedCV.content
                };

                if (cvData.length >= 3) {
                    setShowReplaceCVPopup(true);
                } else {
                    setCvData(prevCvData => [newCV, ...prevCvData]);
                    setSelectedCV(newCV);
                }
            }
        } catch (error) {
            console.error('Error updating/adding CV:', error);
        }
    };

    const handleDeleteClick = async () => {
        try {
            const response = await axios.delete(backendUrl+`/cvs/${selectedCV.id}`);

            const updatedCVData = cvData.filter(cv => cv.id !== selectedCV.id);
            setCvData(updatedCVData);
            setSelectedCV(updatedCVData.length ? updatedCVData[0] : {});

            setConfirmDelete(false);
        } catch (error) {
            console.error('Error deleting CV:', error);
        }
    };

    const maxCVsReached = cvData.length >= 3;

    return (
        <div
        style={{
            position: "relative",
            width:isMobileView?"90%": "60%",
            left:isMobileView?"5%": "20%"
        }}
        >
        <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
          >
        <Typography variant="h5" className={classes.title}  style={{ marginBottom: '30px', marginTop: '30px', fontWeight: "bold" }}>
            Manage Your CVs
        </Typography>
        </motion.div>

        <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6 }}
        >

        <Card
        className={classes.card} 

        >
        <Tooltip
        className={classes.tooltip}
        title={
            <Typography
            style={{
                fontSize: ".9rem"
            }}
            >
            Upload your CV to enable our AI to craft personalized cover letters and responses to job ad questions. Link Letter Pro uses your CV details, combined with job ad specifics, to create tailored applications that stand out. This not only enhances the relevance of your applications but also significantly saves time in your job hunting process.
            </Typography>
        }
        >

        <InfoIcon className={classes.infoIcon} />
            </Tooltip>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6"  className={classes.typography}  gutterBottom>Select CV</Typography>
                        <ToggleButtonGroup
                        
                        value={cvData.indexOf(selectedCV)} exclusive onChange={handleToggle}>
                            {cvData.map((cv, index) => (
                                <ToggleButton
                                
                                key={index} value={index} 
                                style={{
                                    width: isMobileView?"100px":null,
                                    fontSize: isMobileView?"0.9rem":null,
                                    height: isMobileView?"55px":null,
                                }}
                                className={classes.toggleButton}>
                                    {cv.title}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom>Current CV Title:</Typography>
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={selectedCV.title || ''}
                            onChange={(e) => setSelectedCV(prevState => ({ ...prevState, title: e.target.value }))}
                            disabled={selectedCV.id !== undefined}
                        />
                    </Grid>
                </Grid>

                {isUploadMode ? (
                    <CVUploader />
                ) : (
                    <Grid container spacing={2} style={{ marginTop: '20px' }}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" gutterBottom>CV Content:</Typography>
                            <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            style={{
                                                color: '#544C40'
                                            }}
                                            checked={isFileMode}
                                            onChange={() => setIsFileMode(!isFileMode)}
                                            color="primary"
                                        />
                                    }
                                    label={isFileMode ? "File" : "Text"}
                                />
                            </FormGroup>
                        </Grid>

                        {isFileMode ? (
               <div {...getRootProps()} style={{
                border: '2px dashed gray',
                borderRadius: '4px',
                padding: '20px',
                textAlign: 'center',  // This will ensure the text inside the <p> tags is centered
                cursor: 'pointer',
                display: 'flex',  // use flexbox for layout
                flexDirection: 'column',  // stack child elements vertically
                alignItems: 'center',  // center child elements horizontally
                justifyContent: 'center',  // center child elements vertically
                height: '200px', // adjust as needed
            }}>
                <input {...getInputProps()} />
                {filename ? (
                    <div>
                        <span role="img" aria-label="file-icon" style={{ fontSize: '2em' }}>📄</span>
                        <p>{filename}</p>
                    </div>
                ) :
                    isDragActive ?
                    <Typography variant="body1">Drop your CV file here...</Typography> :
                    <Typography variant="body1">Drag & drop your CV file here, or click to select one</Typography>
                }
            </div>
            
                        ) : (
                            <TextField
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={10}
                                value={selectedCV.content || ''}
                                onChange={(e) => setSelectedCV(prevState => ({ ...prevState, content: e.target.value }))}
                            />
                        )}
                        </Grid>
                    </Grid>
                )}

                {maxCVsReached ? (
                    <Typography variant="body1" color="info">
                        You have reached the maximum number of CVs. Please delete one to add a new CV.
                    </Typography>
                ) : null}
<Grid container justifyContent="space-between" alignItems="center">
    <Grid item>
        <IconButton 
            color="primary" 
            className={classes.actionButton}
            onClick={isFileMode?handleFileUpload:handleUpdateClick}
            title={maxCVsReached ? 'Update CV' : 'Add New CV'}
        >

            <SaveIcon />
        </IconButton>

        <IconButton 
            color="secondary" 
            className={classes.actionButton}
            onClick={() => setConfirmDelete(true)}
            title="Delete CV"
        >
            <DeleteIcon />
        </IconButton>

        <IconButton 
            color="default" 
            className={classes.actionButton}
            onClick={handleNewCVClick}
            title="New CV"
        >
            <AddIcon />
        </IconButton>
    </Grid>
    <Grid item>
        <Button
        color="default" 
            variant="contained"
            endIcon={<OfflineBoltIcon  />} // just a sample icon, replace as needed
            className={classes.actionButtonEnhance}
            onClick={handleClickEnhance}
        >
            Enhance
        </Button>
    </Grid>
</Grid>

                <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete the CV titled <strong>{selectedCV.title}</strong>?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setConfirmDelete(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDeleteClick} color="secondary">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={showReplaceCVPopup} onClose={() => setShowReplaceCVPopup(false)}>
                    <DialogTitle>
                        Choose a CV to Replace
                        <IconButton aria-label="close" className={classes.closeButton} onClick={() => setShowReplaceCVPopup(false)}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            You have reached the maximum number of CVs. Please choose one to replace with the new CV.
                        </DialogContentText>
                        <Grid container spacing={2}>
                            {cvData.map(cv => (
                                <Grid item xs={12} key={cv.id}>
                                    <Button variant="outlined" onClick={() => handleReplaceCV(cv.id)}>
                                        Replace: {cv.title}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </DialogContent>
                </Dialog>

            </CardContent>
        </Card>
        </motion.div>

        
        <Dialog 
            maxWidth={loading? "xs" : "md"}
            style={{
            }}
             // Set to desired value
            fullWidth 
        open={loading || enhancedTips !== ''} onClose={() => {
            setEnhancedCVContent('')
        }
            
            }>
    <DialogTitle
    style={{

    }}
    >
  <Typography variant="h6" style={{ fontSize: "2.4rem", fontFamily: '"Bungee", sans-serif', marginTop: "0px" }}>
    {loading ? "Loading..." : (listItems.length > 0 ? "Enhancement Tips" : (enhancedCVContent.length > 0 ? "Enhanced CV" : ""))}
</Typography>
</DialogTitle>

<DialogContent  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    {loading ? (
        <CircularProgress
        style={{
            width: "30px",
            color: "red"
        }}
        />
    ) : listItems.length > 0 ? (
        <div>
            {listItems.map((item, index) => (
                <div key={index} style={{ marginBottom: '10px', display: 'block' }}>
                    <Typography variant="body1">
                        {item}
                    </Typography>
                </div>
            ))}
        </div>
    ) : enhancedCVContent.length > 0 ? (
        <div
        style={{
            width: "100%"
        }}
        >
        <Typography variant="body1">
            <TextField 
                fullWidth
                multiline
                rows={25}
                variant="outlined"
                value={enhancedCVContent}
                onChange={handleTextFieldChange}
                placeholder="Enhancement tips will appear here."


            />
        </Typography>

        </div>
    ) : null}
</DialogContent>

<DialogActions>

    {!loading && (
            <Button 
            className={classes.actionButton}
            onClick={() => setEnhancedTips('')} 
            style={{ position: 'relative', left: '-40%', }} 
            >
            Close
        </Button>
    )}
    {!loading && (
        <Button 
            className={classes.actionButton}
            onClick={handleApplyEnhancement}

            
            >
            Apply Enhancements
        </Button>
    )}
    {!loading && (
        <Button
        
        className={classes.actionButton}
        onClick={
            enhancedCVContent.length > 0 ? handleCopyToClipboard: handleCopyToClipboardTips}
        
        >
            Copy to Clipboard
        </Button>
        
    )}
        { enhancedCVContent.length > 0 && (
        <IconButton 
        color="primary" 
        className={classes.actionButton}
        onClick={
            ()=>{
                setSelectedCV(prevState => ({
                    ...prevState, 
                    content: enhancedCVContent
                }));
                handleUpdateClickEnhance()
            }
            
            }
        title={maxCVsReached ? 'Update CV' : 'Add New CV'}
    >
        <SaveIcon />
    </IconButton>
    )}




</DialogActions>

</Dialog>


        </div>
    );
};

export default CvDisplayComponent;
