import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ModalComponent from '../Landing_page/ModalComponent';
import PrivacyPolicyComponent from './PrivacyPolicyComponent';
import TermsOfServiceComponent from './TermsOfServiceComponent';
import { ReactComponent as InstagramIcon } from '../../svg/instagram.svg';
import { ReactComponent as TikTokIcon } from '../../svg/tiktok.svg';
import LetterIcon from '../../svg/speed_email_white.svg';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#1f2029',
    color: '#b1ad98',
    padding: '20px 0',
    marginTop: '80px',
    position: 'relative',
    bottom: '0',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'start', // Align items to the start of the cross-axis
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center', // Center items on small screens
    },
  },
  logoText: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: '30px',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      margin: '10px 0',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    margin: '5px 0',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  logoSubContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  socialIcons: {
    display: 'flex',
    marginTop: '50px',
    gap: '20px',
    [theme.breakpoints.down('sm')]: {
        marginTop: '0px',
      },
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  logoText: {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '0',
  },
  descriptionText: {
    textAlign: 'center',
    marginTop: '0.5rem',
  },
}));

const FooterComponent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [modalContent, setModalContent] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const emailAddress = 'linkletterpro@gmail.com';

  const handleModalOpen = (contentComponent, title) => {
    setModalContent({ component: contentComponent, title });
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={classes.footer}>
    <div className={classes.logoContainer}>
    <div className={classes.logoSubContainer}>
        <p className={classes.logoText}>Link Letter Pro</p>
      <img
        style={{ width: '2.3rem' }}
        src={LetterIcon}
        alt="Letter Icon"
      />
        </div>

      <p className={classes.descriptionText}>AI-driven applications for the modern job seeker.</p>
    </div>
      
      <div className={classes.section}>
        <p><strong>Support</strong></p>
        <a href={`mailto:${emailAddress}`} className={classes.link}>Contact Us</a>
      </div>

      <div className={classes.section}>
        <p><strong>Legal</strong></p>
        <a onClick={() => handleModalOpen(<PrivacyPolicyComponent />, 'Privacy Policy')} className={classes.link}>
          Privacy Policy
        </a>
        <a onClick={() => handleModalOpen(<TermsOfServiceComponent />, 'Terms of Service')} className={classes.link}>
          Terms of Service
        </a>
      </div>

      {isSmallScreen && <div style={{ width: '100%', height: '1px', backgroundColor: '#FFFFFF', margin: '10px 0' }}></div>}

      <div className={classes.socialIcons}>
        <InstagramIcon aria-label="Instagram" style={{ width: '24px', height: '24px', fill: '#b1ad98' }} />
        <TikTokIcon aria-label="TikTok" style={{ width: '24px', height: '24px', fill: '#b1ad98' }} />
      </div>

      <ModalComponent open={isModalOpen} onClose={handleModalClose} children={modalContent.component} />
    </div>
  );
};

export default FooterComponent;
