import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Avatar, Grid, Card, CardContent, Collapse, Divider, Button, CircularProgress } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import SubscriptionPlan from './SubscriptionPlan';
import LetterPack from './LetterPack';
import axios from 'axios';
import { motion } from 'framer-motion';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import TableComponent from './TableComponent';
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: '100vh',
        display: 'flex',
        minheight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        overflowY: 'auto'
    },
    infoRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1, 0),
    },
    infoRowText: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1, 0),
    },
    card: {
        width: '80%',
        position: "relative",
        left: "0%",
        border: '3px solid #544C40',
        borderRadius: '12px',
        boxShadow: '8px 8px 16px rgba(52, 47, 40, 0.85), -8px -8px 16px rgba(252, 241, 216, 0.9)',
        padding: theme.spacing(3),
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)', 
        overflow: 'hidden',
        backgroundColor: '#FCF1D8',
        [theme.breakpoints.down('sm')]: {  // Mobile view adjustments
            width: '90%',
            position: "relative",
            left: '0%'


          }
    },
    profileHeader: {
        marginBottom: theme.spacing(2)
    },
    actionButton: {
        margin: theme.spacing(1, 0),
        justifyContent: 'flex-start',
        textTransform: 'none',
        width: '100%',
        color: theme.palette.text.primary,
        fontSize: "1.2rem"
    },
    dangerButton: {
        color: theme.palette.error.main,
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    featureInfo: {
        margin: theme.spacing(2, 0)
    },
    divider: {
        marginBottom: theme.spacing(2),
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,  // to ensure the overlay is on top
    }
}));




export default function AccountPage({ user, backendUrl, userCreditData, setUser }) {
    const classes = useStyles();
    const [showPaymentHistory, setShowPaymentHistory] = useState(false);
    const [showSubscriptionPlan, setShowSubscriptionPlan] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectedPack, setSelectedPack] = useState(null);
    const [showLetterPack, setShowLetterPack] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const toggleSubscriptionPlan = () => {
        console.log(user.transactions)
        if (showLetterPack) {
            setShowLetterPack(false);
        }
        setShowSubscriptionPlan(!showSubscriptionPlan);
    };

    const toggleLetterPack = () => {
        if (showSubscriptionPlan) {
            setShowSubscriptionPlan(false);
        }
        setShowLetterPack(!showLetterPack);
    };

    const clickLogout = () => {
        localStorage.removeItem("JWT_ACCESS_TOKEN");
        localStorage.removeItem("TOKEN_TIMESTAMP");
        localStorage.setItem('user',"");
        setUser({
            name: "",
            email: "",
            picture: "",
            userId: "",
          })
        
    }


    const buySubscription = async (plan_days, price) => {
        try {
            setIsLoading(true);
            const response = await axios.post(backendUrl+'/subscription/update/', {
                // user_id: user.id,
                user_id: user.userId,
                plan_days: plan_days,
                price_amount: price
            });
            
            if (response.data.payment_url) {
                window.location.href = response.data.payment_url;
            } else {
                // Handle the error, maybe set some state to show a message to the user
            }
        } catch (error) {
            console.error("Error purchasing subscription:", error);
            // Handle the error, maybe set some state to show a message to the user
        }
        finally {
            setIsLoading(false);
        }
    };
    
    const buyLetterPack = async (pack) => {
        setIsLoading(true);
        try {
            const response = await axios.post(backendUrl+'/letter-credits/update/', {
                // user_id: user.id, 
                user_id: user.userId,
                bought_credits: pack.letters,
                price_amount: pack.price,
            });
            
            if (response.data.payment_url) {
                window.location.href = response.data.payment_url;
                setSelectedPack(pack);
            } else {
                // Handle the error, maybe set some state to show a message to the user
            }
        } catch (error) {
            console.error("Error purchasing letter pack:", error);
            // Handle the error, maybe set some state to show a message to the user
        }
        finally {
            setIsLoading(false);
        }
    };
    
    
    
    return (
        <div className={classes.root}>
                        {isLoading && (
                <div className={classes.overlay}>
                    <CircularProgress />
                </div>
            )}

            <Card className={classes.card}>
                <CardContent>
                    <div className={classes.profileHeader}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }}>Profile Information</Typography>
                        <Divider className={classes.divider} />
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={4}>
                                <Avatar alt={user.name} src={user.picture} style={{ width: 80, height: 80 }} />
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="h6">{user.name}</Typography>
                                <Typography variant="subtitle1">{user.email}</Typography>
                            </Grid>
                        </Grid>
                    </div>

                    <div className={classes.infoRow}>
                    <Typography 
                    style={{

                    }}
                    variant="subtitle1">
                        Premium Plan: 
                        {userCreditData.subscription_date 
                            ? <span style={{ fontWeight: 'bold', marginLeft: '8px' }}>{"unlimited to "+(userCreditData.subscription_date)}</span>
                            : <span style={{ fontWeight: 'bold', marginLeft: '8px' }}>Free</span>
                        }
                    </Typography>
                </div>

                <div className={classes.infoRow}>
                    <Typography variant="subtitle1">
                        Credits Left: 
                        <span style={{ fontWeight: 'bold', marginLeft: '8px' }}>{userCreditData.bought_credits}</span>
                    </Typography>
                </div>

                    <Button className={classes.actionButton} onClick={() => setShowPaymentHistory(!showPaymentHistory)}>
                        <HistoryIcon className={classes.icon}/> Payment History
                    </Button>
                    <Collapse in={showPaymentHistory}>
                        <TableComponent
                        userData={userCreditData}
                        />
                    </Collapse>

                <Button className={classes.actionButton} onClick={toggleLetterPack}>
                            <MailOutlineIcon className={classes.icon}/> Buy Credits Packs
                        </Button>
                    <Collapse in={showLetterPack}>
                        {selectedPack 
                            ? <Typography className={classes.featureInfo}>You've bought a pack of {selectedPack.letters} letters.</Typography>
                            : (
                                <>
                                    <LetterPack classes={classes} onSelect={buyLetterPack} />
                                </>
                            )
                        }
                    </Collapse>

                    <Button className={classes.actionButton} onClick={toggleSubscriptionPlan}>
                <TimelapseIcon className={classes.icon}/> Subscription Plan
            </Button>
                   <Collapse in={showSubscriptionPlan}>

                        <SubscriptionPlan classes={classes} onSelect={buySubscription} />

                    </Collapse>
                    <Link to="/" className={classes.link}>
                    <Button
                    onClick={clickLogout}
                    className={classes.actionButton}>
                        <ExitToAppIcon className={classes.icon}/> Logout
                    </Button>
                    </Link>
                    <Button className={[classes.actionButton, classes.dangerButton].join(' ')}>
                        <DeleteOutlineIcon className={classes.icon}/> Delete Account
                    </Button>
                </CardContent>
            </Card>

        </div>
    );
}
