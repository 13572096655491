import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHeader: {
    backgroundColor: '#ede6d8',
  },
  tableCell: {
    border: '1px solid #e0e0e0',
  },
  tableContainer: {
    height: 300, // Set a fixed height
    overflow: 'auto', // Enable scrolling
    border: '2px solid black',
    borderRadius: "5px"
  }
});


export default function TableComponent({ userData }) {
  const classes = useStyles(); // use the styles

  console.log("userData1")
  console.log(userData.tra)

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell className={classes.tableCell}>Amount Payed</TableCell>
            <TableCell className={classes.tableCell}>Transaction Date</TableCell>
            <TableCell className={classes.tableCell}>Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(userData.transactions) && userData.transactions.map((row, index) => (
            <TableRow key={index}>
              <TableCell className={classes.tableCell}>{row.amount_payed} $</TableCell>
              <TableCell className={classes.tableCell}>{new Date(row.transaction_date).toLocaleString()}</TableCell>
              <TableCell className={classes.tableCell}>
                {row.type === "letter_credits" ? "Credits" : "Subscription"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
