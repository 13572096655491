import { GoogleLogin } from '@react-oauth/google';
import React, { useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // import the hook

const GoogleLoginButton = ({ backendUrl, onUserLogin, onClose, setLoading }) => {
    const navigate = useNavigate(); // Use the hook here to get the navigate function

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    };

    const onSuccess = async (credentialResponse) => {
        setLoading(true)
        const tokenId = credentialResponse.credential;
        const decodedToken = parseJwt(tokenId);
    
        if (decodedToken) {
            
            console.log("Decoded token:", decodedToken);

            const loggedInUser = {
                name: decodedToken.name,
                email: decodedToken.email,
                picture: decodedToken.picture,
                userId: decodedToken.sub,
                tokenId: tokenId
                
                // Assuming sub is the user ID from Google
            };
    
            console.log(typeof onUserLogin);  // should print 'function'
            onUserLogin(loggedInUser);
    
            // Send the user info to the backend
            console.log("Sending to backend:", {
                google_id: loggedInUser.userId,
                email: loggedInUser.email,
                full_name: loggedInUser.name,
                picture_url: loggedInUser.picture,
                email_verified: true,
                tokenId: loggedInUser.tokenId
            });
 
            try {
                const response = await axios.post(`${backendUrl}/users/`, {
                    google_id: loggedInUser.userId,
                    email: loggedInUser.email,
                    full_name: loggedInUser.name,
                    picture_url: loggedInUser.picture,
                    email_verified: true,
                    tokenId: loggedInUser.tokenId
                    // Assuming all Google accounts are email verified, adjust if needed.
                });

                const fiveHoursInMilliseconds = 5 * 60 * 60 * 1000;
                const expirationTimestamp = Date.now() + fiveHoursInMilliseconds;

                localStorage.setItem("JWT_ACCESS_TOKEN", response.data.jwt);
                localStorage.setItem("TOKEN_TIMESTAMP", expirationTimestamp.toString()); // store current timestamp
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.jwt;
                setLoading(false)
                onClose();

                navigate("/upload"); 

                console.log("Backend response:", response.data);
            } catch (error) {
                console.error("Failed to update the user in the backend:", error);
            }
        }
    };

    const onError = (response) => {
        console.error("Error from Google:", response);
        console.log('Login Failed');
        setLoading(false)
    };

    return (
        <div
         style={containerStyle}>
            <GoogleLogin
                onClick={()=>{
                    setLoading(true)
                }}
                onSuccess={onSuccess}
                onError={onError}
                style={{
                    height: "40px"
                }}
            />
        </div>
    );
};

const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '10vh',
    width: '100%'
};

export default GoogleLoginButton;
