// ModalComponent.js
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

const ModalComponent = ({ open, onClose, title, children }) => {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title">
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    );
}

export default ModalComponent;
