import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Define styles for the component
const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
        backgroundColor: 'white',
        color: 'black',
        overflowY: 'auto',
        maxHeight: '80vh',
    },
    heading: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
    },
    subHeading: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    paragraph: {
        textAlign: 'justify',
        marginBottom: theme.spacing(1),
    },
}));

const TermsOfServiceComponent = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <h1 className={classes.heading}>Terms of Service</h1>

            <p className={classes.paragraph}>
                Welcome to LinkLetterPro. By using our application, you agree to the following terms and conditions. Please read them carefully.
            </p>

            <h2 className={classes.subHeading}>Use of Service</h2>
            <p className={classes.paragraph}>
                LinkLetterPro provides AI-driven solutions for creating cover letters and responding to job advertisements. The service is based on the data provided by the user, including LinkedIn job ads and personal CVs. Users are responsible for the accuracy and legality of the data they provide.
            </p>

            <h2 className={classes.subHeading}>User Accounts</h2>
            <p className={classes.paragraph}>
                When you create an account with LinkLetterPro, you must provide information that is accurate and complete. You are responsible for maintaining the security of your account and any activities under your account.
            </p>

            <h2 className={classes.subHeading}>Prohibited Use</h2>
            <p className={classes.paragraph}>
                You may not use LinkLetterPro to engage in illegal, misleading, or unethical activities. This includes submitting false information, infringing on intellectual property rights, or conducting fraud.
            </p>

            <h2 className={classes.subHeading}>Intellectual Property</h2>
            <p className={classes.paragraph}>
                All rights in the application and its content, including our proprietary algorithms, belong to LinkLetterPro. Your use of our service does not grant you ownership of any intellectual property rights in our service or the content you access.
            </p>

            <h2 className={classes.subHeading}>Disclaimer of Warranties</h2>
            <p className={classes.paragraph}>
                LinkLetterPro is provided "as is," without any warranties of any kind, either express or implied. We do not guarantee the accuracy, reliability, or completeness of any generated content.
            </p>

            <h2 className={classes.subHeading}>Limitation of Liability</h2>
            <p className={classes.paragraph}>
                LinkLetterPro shall not be liable for any indirect, incidental, special, consequential or punitive damages, including loss of profits, data or use.
            </p>

            <h2 className={classes.subHeading}>Prohibited Use</h2>
            <p className={classes.paragraph}>
                You may not use LinkLetterPro to engage in illegal, misleading, or unethical activities. This includes submitting false information, infringing on intellectual property rights, or conducting fraud.
            </p>

            <h2 className={classes.subHeading}>No Unauthorized Automation</h2>
            <p className={classes.paragraph}>
                The use of bots, automation software, or any other unauthorized means to exploit the capabilities of LinkLetterPro is strictly prohibited. Our system continuously monitors for such activities, and any detected misuse will result in immediate action, including account suspension or termination. We are committed to ensuring the fairness and security of our service for all users.
            </p>

            <h2 className={classes.subHeading}>Changes to Terms</h2>
            <p className={classes.paragraph}>
                We reserve the right to modify these terms at any time. Any changes will be posted on this page and are effective immediately.
            </p>

            <h2 className={classes.subHeading}>Contact Us</h2>
            <p className={classes.paragraph}>
                If you have any questions about these Terms, please contact us.
            </p>
        </div>
    );
}

export default TermsOfServiceComponent;
