import React, { useRef, useState } from 'react';
import { Button, Paper, Typography } from '@material-ui/core';

const CVUploader = ({ onUpload }) => {
    const [dragging, setDragging] = useState(false);
    const fileInputRef = useRef(null);

    const handleDragEnter = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);
        handleFiles(e.dataTransfer.files);
    };

    const handleFiles = (files) => {
        if (onUpload && files.length > 0) {
            onUpload(files[0]);  // assuming only one file is allowed
        }
    };

    return (
        <div style={{ width: '300px', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Paper 
                variant="outlined" 
                style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
                onDragEnter={handleDragEnter}
                onDragOver={(e) => e.preventDefault()}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                elevation={dragging ? 3 : 1}
            >
                <input 
                    type="file" 
                    ref={fileInputRef} 
                    style={{ display: 'none' }} 
                    onChange={(e) => handleFiles(e.target.files)} 
                />
                <Typography variant="body1">
                    {dragging ? 'Drop your CV here' : 'Drag & Drop your CV here'}
                </Typography>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={() => fileInputRef.current.click()}
                >
                    Or click to upload
                </Button>
            </Paper>
        </div>
    );
};

export default CVUploader;
