const jobMessages = [
    {
        jobAdTitle: "Software Developer Wanted",
        jobAdContent: "Looking for a software developer with 5+ years of experience, a passion for creating scalable applications, and a history of collaborative project work.",
        personalLetterTitle: "I'm a Software Developer...",
        personalLetterBody: "Over the past five years, I've immersed myself in developing scalable and user-friendly applications. My relentless pursuit for coding excellence has led me to not only sharpen my skills but also to contribute to the tech community. I am always on the lookout for challenging projects where I can bring both my technical expertise and collaborative spirit."
    },
    {
        jobAdTitle: "Experienced Journalist Required",
        jobAdContent: "Seeking a seasoned journalist with experience in global reporting, digital storytelling, and a commitment to truth and integrity.",
        personalLetterTitle: "I'm a Journalist...",
        personalLetterBody: "Throughout my career in journalism, I've held truth and integrity at the forefront. Reporting from various global hotspots, my pieces have shed light on untold stories, sparking dialogue and impacting policy. In this digital age, I am committed to delivering insightful and balanced narratives that resonate with diverse audiences."
    },
    {
        jobAdTitle: "HR Manager Position Open",
        jobAdContent: "In search of an HR Manager with over a decade of experience, an understanding of organizational culture, and proficiency in conflict resolution.",
        personalLetterTitle: "I'm a HR Manager...",
        personalLetterBody: "Human Resources isn't just a profession for me; it's a passion. Over a decade of experience has taught me the intricacies of talent management, organizational culture, and conflict resolution. My primary focus has always been fostering an environment where both the company and its employees can thrive and achieve mutual success."
    },
    {
        jobAdTitle: "Data Scientist Needed",
        jobAdContent: "Hiring a data scientist with a holistic approach, a blend of statistical rigor with business acumen, and a track record of turning raw data into actionable insights.",
        personalLetterTitle: "I'm a Data Scientist...",
        personalLetterBody: "In the realm of zeros and ones, I've discovered patterns and insights that have driven strategic business decisions. My approach to data science is holistic, ensuring a blend of statistical rigor with business acumen. I pride myself on turning raw data into actionable insights that propel growth."
    },
    {
        jobAdTitle: "Project Manager Job Opening",
        jobAdContent: "Looking for a project manager with a systematic approach, experience in leading cross-functional teams, and a history of delivering projects beyond expectations.",
        personalLetterTitle: "I'm a Project Manager...",
        personalLetterBody: "Delivering projects on time, within budget, and beyond expectations is where I've built my reputation. My systematic approach to project management, combined with my ability to lead cross-functional teams, has consistently yielded positive results. I am always eager to take on projects that push boundaries and set new industry standards."
    },

    {
        jobAdTitle: "Digital Marketing Specialist Needed",
        jobAdContent: "Looking for a digital marketer with a knack for leveraging digital channels, expertise in SEO and SEM, and a proven track record in driving online traffic and conversions.",
        personalLetterTitle: "I'm a Digital Marketing Specialist...",
        personalLetterBody: "Digital Marketing has been my playground for years. From formulating SEO strategies that rank pages higher to leveraging SEM for immediate visibility, I've done it all. My campaigns have consistently led to increased traffic and, more importantly, conversions. I'm always excited to craft strategies that connect businesses with their audience in the digital realm."
    },
    {
        jobAdTitle: "Creative Graphic Designer Wanted",
        jobAdContent: "In search of a graphic designer with a fresh perspective, experience across various mediums, and a portfolio that speaks louder than words.",
        personalLetterTitle: "I'm a Graphic Designer...",
        personalLetterBody: "Design, for me, is not just about making things look good. It's about telling a story, evoking emotions, and making a lasting impact. Over the years, I've worked across various mediums, always bringing a fresh perspective. My portfolio showcases my journey, a blend of creativity and purpose in every piece."
    },
    {
        jobAdTitle: "Customer Support Specialist Position Open",
        jobAdContent: "Looking for a customer support specialist who puts the customer first, has excellent communication skills, and can handle challenging situations with grace.",
        personalLetterTitle: "I'm a Customer Support Specialist...",
        personalLetterBody: "My mantra in customer support is simple: The customer comes first. Over the years, I've faced and resolved myriad challenges, always ensuring the customer left satisfied. My communication skills, coupled with patience, ensure that even in the most challenging situations, I can find a solution that works for everyone."
    },
    {
        jobAdTitle: "E-commerce Manager Required",
        jobAdContent: "Seeking an e-commerce manager with experience in managing online stores, familiarity with e-commerce platforms, and a track record of increasing online sales.",
        personalLetterTitle: "I'm an E-commerce Manager...",
        personalLetterBody: "Managing online stores, optimizing product listings, and driving sales have been my forte. I'm well-versed with major e-commerce platforms and have strategies tailored for each. My endeavors have consistently led to increased online sales, and I am always on the lookout for innovative ways to enhance the online shopping experience."
    }
    // ... Add more jobs as needed
];

export default jobMessages;
