import './App.css';
import AppBarComponent from './components/Navigation_bar/AppBarComponent';
import SettingsPage from './components/Settings_page/SettingsPage';
import LandingPage from './components/Landing_page/LandingPage';
import CvDisplayComponent from './components/CV_page/CvDisplayComponent';
import { useEffect, useState } from 'react';
import AccountPage from './components/Account_page/AccountPage';
import axios from 'axios';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { createTheme, ThemeProvider, CssBaseline } from '@material-ui/core';
import GoogleSignInPopup from './components/Google_login/GoogleSignInPopup';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Button } from '@material-ui/core';
import TutorialDialog from './components/Navigation_bar/TutorialDialog';
import FooterComponent from './components/Navigation_bar/FooterComponent';
import { initializeAnalytics } from './analytics';

import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

const dummyUser = {
  name: "",
  email: "",
  picture: "",
  userId: "",
}


const fetchUserTemp = {
  name: "",
  email: "",
}
const backendUrl = "https://backend-personal-letter-python-hfzyyicqga-ew.a.run.app";
// const backendUrl = "http://127.0.0.1:8000";
const CACHE_KEY = "cached_cvs";

const useStyles = makeStyles((theme) => ({

}));

function App() {
  const [cvs, setCvs] = useState([]);
  const [user, setUser] = useState(dummyUser);
  const [userCreditData, setUserCreditData] = useState(fetchUserTemp);
  const [isGooglePopup, setIsGooglePopup] = useState(false);
  const classes = useStyles();
  const [openTutorial, setOpenTutorial] = useState(false);

  const handleOpenTutorial = () => {
    setOpenTutorial(true);
  };

  const handleCloseTutorial = () => {
    setOpenTutorial(false);
  };


  useEffect(() => {
    initializeAnalytics();
  }, []);

  function handleTokenExpiry() {
    // Clear token from storage
    localStorage.removeItem("JWT_ACCESS_TOKEN");
  
    // Set user back to dummy or unauthenticated state
    setUser(dummyUser);
    // Open login prompt
    setIsGooglePopup(true);
  }
  
  // Setup Axios Interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.log("Token expired. Prompting login...");
      // Trigger your function to handle expired token here
      handleTokenExpiry();
    }
    return Promise.reject(error);
  }
);

  
  const theme = createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '*::-webkit-scrollbar': {
            width: '8px',
            backgroundColor: '#544C40',
          },
          '*::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
            backgroundColor: '#544C40',
          },
          '*': {
            scrollbarWidth: 'thin',
            scrollbarColor: '#544C40 #544C40',
          },
        },
      },
    },
  });
  
  

  useEffect(() => {
    console.log("Trigger fetch data")
    // First, attempt to load CVs from local storage
    const cachedCvs = localStorage.getItem(CACHE_KEY);
    if (cachedCvs) {
      setCvs(JSON.parse(cachedCvs));
    }

    // Then fetch CVs from the backend
    const fetchCVs = async () => {
      try {
        const response = await axios.get(backendUrl + `/users/${user.userId}/cvs/`);
        const cvsData = response.data;
        console.log(cvsData)
        setCvs(cvsData);

        // Update local storage cache
        localStorage.setItem(CACHE_KEY, JSON.stringify(cvsData));
      } catch (error) {
        console.error("Error fetching CVs", error);
      }
    };

    const fetchUserData = async () => {
      try {
        const response = await axios.get(backendUrl + `/users/${user.userId}/`);
        const userData = response.data;
        console.log("userData")
        console.log(userData)
        setUserCreditData(userData.user);


      } catch (error) {
        console.error("Error fetching Users", error);
      }
    };

    fetchUserData();
    fetchCVs();
  }, [user]);

    useEffect(() => {
      if (user && user !== dummyUser) {
          localStorage.setItem('user', JSON.stringify(user));

          window.postMessage({type: "USER_DATA_UPDATED", payload: user}, "*");
      }
      const jwt = localStorage.getItem("JWT_ACCESS_TOKEN");
      if (jwt) {
          // Set JWT for Axios
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;
      }

  }, [user]);



  useEffect(() => {
    document.title = 'Free ChatGPT Cover Letter and Job-Ad Question Answering Tool';
    const jwt = localStorage.getItem("JWT_ACCESS_TOKEN");
    const userLocalStorage = localStorage.getItem("user");
    if (userLocalStorage) {
        const userObject = JSON.parse(userLocalStorage);
        setUser(userObject);
        if (userObject) {
            window.postMessage({ type: "USER_DATA_UPDATED", payload: userObject }, "*");
        }
    } else {
        setUser(null);
    }
    if (jwt) {
        // Set JWT for Axios
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;
    }
}, []);


useEffect(() => {
  if (user && user !== dummyUser) {
    console.error("set User")
    console.error(user)
    localStorage.setItem('user', JSON.stringify(user));
  }
}, [user]);

useEffect(() => {
  window.addEventListener('message', (event) => {
    // Check the origin for security
    if (event.source !== window)
      return;
  
    if (event.data.type && event.data.type === "FROM_EXTENSION_TO_PAGE_REQUEST_USER_DATA") {
      // Send user data back to the content script
      const user = {
        name: "John Doe",
        email: "johndoe@example.com",
        // ... any other user data
      };
      window.postMessage({
        type: "FROM_PAGE_TO_EXTENSION_USER_DATA_RESPONSE",
        payload: { user }
      }, "*");
    }
  });
  
}, []);


  const handleClosePopup = () => {
    setIsGooglePopup(false);
};

const handleOpenPopup = () => {
  setIsGooglePopup(true);
};

return (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <div className="App" style={{ backgroundColor: null }}>
    {/* backgroundColor: "#F5F5F5" */}
      <GoogleOAuthProvider clientId="115212864837-dgv75a0bpqorpovsc3c5d9f76rbt29bs.apps.googleusercontent.com">
        <Router>
          <AppBarComponent
            handleOpenPopup={handleOpenPopup} user={user}
            open={openTutorial}
            handleOpenTutorial={handleOpenTutorial}
          />
          <div style={{ position: "relative", top: "65px" }}>
          <GoogleSignInPopup
            open={isGooglePopup}
            onClose={handleClosePopup}
            backendUrl={backendUrl}
            onUserLogin={setUser}
            />
            <Routes>
              <Route path="/upload" element={<CvDisplayComponent user={user} backendUrl={backendUrl} cvData={cvs} />} />
              <Route path="/settings" element={<SettingsPage backendUrl={backendUrl}  />} />
              <Route path="/account" element={<AccountPage setUser={setUser} userCreditData={userCreditData} backendUrl={backendUrl} user={user} />} />
              <Route path="/" element={<LandingPage handleOpenPopup={handleOpenPopup} user={user} backendUrl={backendUrl} onUserLogin={setUser} />} />
            </Routes>
          </div>
        </Router>
      </GoogleOAuthProvider>
      <TutorialDialog
      open={openTutorial}
      onClose={handleCloseTutorial}
      
      />
     <FooterComponent /> 
    </div>
  </ThemeProvider>
  
);

}

export default App;
