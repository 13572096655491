import React from 'react';
import { Card, CardHeader, CardContent, Typography, Button, Grid, makeStyles } from '@material-ui/core';
import { motion } from 'framer-motion';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TimelapseIcon from '@material-ui/icons/Timelapse';

const useStyles = makeStyles(theme => ({
    centeredContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

    },
    benefitBox: {
        maxWidth: "350px",
        maxheight: "540px",
        padding: theme.spacing(2),
        backgroundColor: 'white',
        color: '#544C40',  // Text color
        border: '3px solid #544C40',  // Thicker border
        borderRadius: '12px',  // Bold corners
        boxShadow: '8px 8px 16px rgba(52, 47, 40, 0.25), -8px -8px 16px rgba(252, 241, 216, 0.9)',  // Darker shadow
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          backgroundColor: '#ffffff',
          boxShadow: '4px 4px 8px rgba(52, 47, 40, 0.25), -4px -4px 8px rgba(252, 241, 216, 0.9)',  // Adjusted shadow depth on hover
        },
        '&:active': {
          boxShadow: 'inset 4px 4px 8px rgba(52, 47, 40, 0.25), inset -4px -4px 8px rgba(252, 241, 216, 0.9)',  // Pressed effect on active
        }
      },
      cardHeaderTitle: {
        fontFamily: '"Bungee", sans-serif',
        fontSize: "20px"
    },
    ctaButton: {

        backgroundColor: '#bbfac4',
        color: '#544C40',  // Text color
        border: '3px solid #544C40',  // Thicker border
        borderRadius: '12px',  // Bold corners
        boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.8), -8px -8px 16px rgba(0, 0, 0, 0.3)',
        padding: theme.spacing(1.5, 4),
        fontSize: '0.8rem',
        marginBottom: "10px",
        marginTop: "5px",
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          backgroundColor: '#6cbd78',  // Slight color shift on hover
          color: '#FFFFFF',  // Text color
          boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.8), -8px -8px 16px rgba(255, 255, 255, 0.1)',
        },
        '&:active': {
          boxShadow: 'inset 4px 4px 8px rgba(52, 47, 40, 0.25), inset -4px -4px 8px rgba(252, 241, 216, 0.9)',  // Pressed effect on active
        }
      }
}));

const subscriptionVariants = {
    hidden: { opacity: 0, scale: 0.8, y: -20 },
    visible: { opacity: 1, scale: 1, y: 0 }
};

export default function SubscriptionPlan({ onSelect }) {

    const classes = useStyles();


    const basePricePerMonth = 12; // $12 for 1 month

    // Function to calculate the savings percentage
    function calculateSavings(basePrice, totalPrice, months) {
        const fullPrice = basePrice * months;
        return Math.round((1 - (totalPrice / fullPrice)) * 100);
    }


    // Mock prices
    const plans = [
        {
            name: "Bronze Plan - 1 month",
            description: "1 month unlimited cover letters",
            iconSize: '3.2rem',
            price: basePricePerMonth,
            savings: 0,  // Base plan, so no savings
            color: '#cd7f32',
            days: 30,
        },
        {
            name: "Silver Plan - 2 month",
            description: "2 months unlimited cover letters",
            iconSize: '3.2rem',
            price: 22,
            savings: calculateSavings(basePricePerMonth, 22, 2), // Calculate savings
            color: '#C0C0C0',  // silver color
            days: 60,
        },
        {
            name: "Gold Plan - 3 month",
            description: "3 months unlimited cover letters",
            iconSize: '3.2rem',
            price: 32,
            savings: calculateSavings(basePricePerMonth, 32, 3), // Calculate savings
            color: '#FFD700',  // gold color
            days: 90,
        },
    ];
    


    return (
        <Grid container spacing={3}>
            
            {plans.map((plan, index) => (
                <Grid key={index} item xs={12} sm={4}>
                    <motion.div 
                        variants={subscriptionVariants} 
                        initial="hidden" 
                        animate="visible"
                        whileHover={{ scale: 1.03 }}
                    >
      <Card className={classes.benefitBox}>
    <TimelapseIcon style={{ fontSize: plan.iconSize, margin: '0 auto', display: 'block', color: plan.color }} />
    <CardHeader 
        title={plan.name} 
        subheader={
            <div>
                {'🦾 GPT-4 powered AI outputs'}
                <br />
                {'✉️ Unlimited cover letters'}
                <br />
                {'📌 Unlimited Job-Ad question answers'}
                <br />
                {'📝 Unlimited access to AI CV enhancement'}              

            </div>
        }  // Add this line to display the plan description
        titleTypographyProps={{align: 'center', className: classes.cardHeaderTitle }} 
        subheaderTypographyProps={{align: 'center'}}  // Center the description text
    />
    <CardContent className={classes.centeredContent}>
        <Typography
        
        style={{
            marginTop: "-20px",
            fontWeight: "bold"
        }}
        align="center">{`Price: $${plan.price}`}</Typography>
        {plan.savings > 0 && 
        <Typography align="center" style={{color: 'green'}}>
            {`Save ${plan.savings}%!`}
        </Typography>}
        <Button
         onClick={() => onSelect(plan.days, plan.price)}
        className={classes.ctaButton}>Buy Now</Button>
    </CardContent>
</Card>

                    </motion.div>
                </Grid>
            ))}
        </Grid>
    );
}
